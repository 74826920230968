import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import cn from "classnames";

import { GroupedData, MappedTableData } from "@/hooks/useTableData";

import cls from "./Grid.module.scss";

type Props<T> = MappedTableData<T & GroupedData>;

export function Grid<T>({ data, columns }: Props<T>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={cn(cls.root)}>
      <div className={cls.tableWrap}>
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className={cn(cls.row, cls.header)}>
            {headerGroup.headers.map((header) => {
              return header.column.columnDef.header ? (
                <div
                  key={header.id}
                  className={cn(cls.row_header, cls.row_cell, cls[header.id])}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ) : null;
            })}
          </div>
        ))}
      </div>
      <div className={cls.col}>
        {table.getRowModel().rows.map((row) => {
          const isGroupRow = row.original.isGroupRow;
          return (
            <div
              key={row.id}
              className={cn(cls.row, { [cls.group_row]: isGroupRow })}
            >
              {row.getVisibleCells().map((cell) => {
                const CellWrapper =
                  typeof cell.getValue() !== "object"
                    ? ParagraphWrapper
                    : EmptyWrapper;
                return (
                  <div
                    className={cn(cls.row_cell, cls[cell.column.id])}
                    key={cell.id}
                  >
                    <CellWrapper>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </CellWrapper>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

type tdProps = {
  children: JSX.Element | React.ReactNode;
};

export const EmptyWrapper = ({ children }: tdProps) => {
  return <>{children}</>;
};

export const ParagraphWrapper = ({ children }: tdProps) => {
  return <p>{children}</p>;
};
