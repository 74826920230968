import { BaseProvider, LightTheme } from "baseui";
import React from "react";
// eslint-disable-next-line import/order
import ReactDOM from "react-dom/client";

import "@/styles/index.scss";

import { I18nextProvider } from "react-i18next";
import { Toaster } from "sonner";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import i18n from "./i18n";
import { AppRouter } from "./router/AppRouter";

const engine = new Styletron();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <I18nextProvider i18n={i18n}>
          <Toaster />
          <AppRouter />
        </I18nextProvider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>
);
