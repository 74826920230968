import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@/components/Grid/Grid";
import { TableData, useTableData } from "@/hooks/useTableData";

import { LocationActions } from "./LocationActions";
import { LocationType } from "./types";

type Props = {
  locations: LocationType[];
};

type MappedData = Omit<LocationType, "_id"> & { actions: JSX.Element };

export const LocationsTable: React.FC<Props> = ({ locations }) => {
  const { t } = useTranslation();

  const mappedData = useMemo<MappedData[]>(
    () =>
      locations.map((location) => {
        return {
          ...location,
          actions: <LocationActions location={location} />,
        };
      }),
    [locations]
  );

  const tableData: TableData<MappedData> = {
    data: mappedData,
    columns: [
      {
        title: t("location.name"),
        accessor: "name",
      },
      {
        title: t("currency.actions"),
        accessor: "actions",
      },
    ],
  };

  const { data, columns } = useTableData(tableData);

  return (
    <>
      <Grid data={data} columns={columns} />
    </>
  );
};
