import { InputProps } from "baseui/input";
import { OnChangeParams, Select, Value } from "baseui/select";
import { useEffect, useState } from "react";

import { OptionI } from "@/utils/types";

import { SELECT_STYLE_OVERRIDES } from "./consts";
import cls from "./StatelessSelect.module.scss";

type Props = Omit<React.ComponentProps<typeof Select>, "onChange" | "value"> & {
  options: OptionI[];
  onChange: (value?: string) => void;
  value?: OptionI["id"];
  label?: string;
  placeholder?: InputProps["placeholder"];
};

export const StatelessSelect: React.FC<Props> = ({
  options,
  onChange,
  placeholder,
  value,
  label,
  ...other
}) => {
  const getInitialValue = () => {
    if (!value) return [];

    const findedOption = options.find((option) => option.id === value);
    return findedOption ? [findedOption] : [];
  };

  const [selectedValue, setSelectedValue] = useState<Value>(
    value ? getInitialValue() : []
  );

  const onValueChange = (params: OnChangeParams) => {
    const { value } = params;
    setSelectedValue(value);
    onChange(value[0]?.id?.toString());
  };

  useEffect(() => {
    if (value && !selectedValue.length) setSelectedValue(getInitialValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedValue]);

  useEffect(() => {
    if (!value && selectedValue.length !== 0) setSelectedValue([]);
  }, [value, selectedValue]);

  return (
    <div className={cls.root}>
      {label && <p className={cls.label}>{label}</p>}

      <Select
        options={options}
        labelKey="text"
        valueKey="id"
        placeholder={placeholder}
        maxDropdownHeight="300px"
        onChange={onValueChange}
        value={selectedValue}
        overrides={SELECT_STYLE_OVERRIDES}
        {...other}
      />
    </div>
  );
};
