/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Input } from "@/components/Input/Input";
import { useDebounce } from "@/hooks/useDebounce";
import { useToast } from "@/hooks/useToast";
import { HorseAdvert } from "@/modules/SellList/types";

import { getAdvertByVendorCode } from "./api";
import { SearchDropdown } from "./SearchDropdown/SearchDropdown";
import cls from "./VendorCodeSearch.module.scss";

// eslint-disable-next-line @typescript-eslint/no-var-requires

export const VendorCodeSearch: React.FC = () => {
  const { t } = useTranslation();
  const { showError } = useToast();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [data, setData] = useState<HorseAdvert[]>([]);

  const getAdverts = async () => {
    if (!debouncedSearchQuery) {
      return;
    }

    setLoading(true);
    try {
      const res = await getAdvertByVendorCode(debouncedSearchQuery);
      setData(res.data.data);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdverts();
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setSearchQuery("");
  }, [location]);

  return (
    <div className={cls.root}>
      <Input
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.currentTarget.value)}
        placeholder={t("common.search")}
        className={cls.input}
      />

      <SearchDropdown
        data={data}
        loading={loading}
        isOpen={!!debouncedSearchQuery}
      />
    </div>
  );
};
