import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
};

export const PageTitle: React.FC<Props> = ({ title }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = `RHMB: ${title}`;
  }, [location, title]);

  return null;
};
