import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import {
  CreateSpecializationReq,
  CreateSpecializationRes,
  DeleteSpecializationReq,
  DeleteSpecializationRes,
  UpdateSpecializationReq,
  UpdateSpecializationRes,
  GetAllSpecializationsRes,
} from "./types";

export const getAllSpecializations = async (): Promise<
  AxiosResponse<APIResponse<GetAllSpecializationsRes>>
> => {
  return await axiosApi.get("/specializations/all");
};

export const createSpecialization = async (
  req: CreateSpecializationReq
): Promise<AxiosResponse<APIResponse<CreateSpecializationRes>>> => {
  return await axiosApi.post("/specializations/add", req);
};

export const updateSpecialization = async (
  req: UpdateSpecializationReq
): Promise<AxiosResponse<APIResponse<UpdateSpecializationRes>>> => {
  return await axiosApi.patch("/specializations/update", req);
};

export const deleteSpecialization = async (
  req: DeleteSpecializationReq
): Promise<AxiosResponse<APIResponse<DeleteSpecializationRes>>> => {
  return await axiosApi.delete("/specializations/delete", {
    data: {
      ...req,
    },
  });
};
