import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_PAGINATION_TAKE_OPTIONS,
  DEFAULT_TAKE_COUNT,
} from "@/utils/consts";
import { OptionI } from "@/utils/types";

import { NextIcon, PrevIcon } from "./icons";
import cls from "./PaginationPages.module.scss";

import { Button } from "../Button/Button";
import { StatelessSelect } from "../StatelessSelect/StatelessSelect";

type Props = {
  currentPage: number;
  itemsCount: number;
  takeCount?: number;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  onNextPage: VoidFunction;
  onPrevPage: VoidFunction;
  setTakeCount?: (v: number) => void;
  paginationOptions?: OptionI[];
};

export const PaginationPages: React.FC<Props> = ({
  currentPage,
  itemsCount,
  takeCount = DEFAULT_TAKE_COUNT,
  hasNextPage = true,
  hasPrevPage = true,
  onNextPage,
  onPrevPage,
  setTakeCount,
  paginationOptions = DEFAULT_PAGINATION_TAKE_OPTIONS,
}) => {
  const { t } = useTranslation();
  const [selectedTakeCount, setSelectedTakeCount] = useState(takeCount);

  const toItem = takeCount * currentPage;
  const fromItem = toItem - takeCount + 1;

  const onChange = (value?: string) => {
    value && setSelectedTakeCount(Number(value));
  };

  useEffect(() => {
    selectedTakeCount && setTakeCount ? setTakeCount(selectedTakeCount) : null;
  }, [selectedTakeCount, setTakeCount]);

  return (
    <div className={cls.root}>
      <div className={cls.take}>
        <p>{t("pagination.rowsToShow")}</p>
        <StatelessSelect
          size="compact"
          options={paginationOptions}
          onChange={onChange}
          value={selectedTakeCount.toString()}
          searchable={false}
          clearable={false}
        />
      </div>

      <div className={cls.on_page}>
        <p>{t("pagination.onPageCount", { fromItem, toItem, itemsCount })}</p>
      </div>

      <div className={cls.actions}>
        <Button disabled={!hasPrevPage} onClick={onPrevPage}>
          <PrevIcon />
        </Button>
        <Button disabled={!hasNextPage} onClick={onNextPage}>
          <NextIcon />
        </Button>
      </div>
    </div>
  );
};
