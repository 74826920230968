import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { FileUploader } from "@/components/FileUploader/FileUploader";
import { useToast } from "@/hooks/useToast";

import cls from "./AdvertImages.module.scss";

import { uploadImage } from "../api";

type Props = {
  onImageUploaded: (filename: string) => void;
  onImageDelete: () => void;
  imageSrc: string;
};

export const AdvertImages: React.FC<Props> = ({
  onImageUploaded,
  onImageDelete,
  imageSrc,
}) => {
  const { t } = useTranslation();
  const { showError } = useToast();
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const onFileSelected = async (file: File) => {
    if (!file) return;

    setUploadDisabled(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await uploadImage(formData);
      onImageUploaded(res.data.data.filename);
    } catch (error) {
      showError(error);
    } finally {
      setUploadDisabled(false);
    }
  };

  const onClickDelete = () => {
    onImageDelete();

    // delete image from server
  };

  return (
    <div className={cls.root}>
      {!imageSrc && (
        <FileUploader
          onFileSelected={onFileSelected}
          disabled={uploadDisabled}
        />
      )}

      {imageSrc && (
        <div className={cls.preview}>
          <img src={imageSrc} />
          <Button variant="danger" onClick={onClickDelete}>
            {t("create.deletePhoto")}
          </Button>
        </div>
      )}
    </div>
  );
};
