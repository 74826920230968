import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import { DeleteIcon, EditIcon } from "./icons";
import cls from "./Specializations.module.scss";
import { UpdateSpecialization } from "./UpdateSpecialization";

import { DeleteSpecialization } from "../Specializations/DeleteSpecialization";
import { SpecializationType } from "../Specializations/types";

type Props = {
  specialization: SpecializationType;
};

export const SpecializationActions: React.FC<Props> = ({ specialization }) => {
  const { t } = useTranslation();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <div className={cls.actions}>
        <button type="button" onClick={() => setUpdateModalOpen(true)}>
          <EditIcon />
        </button>
        <button type="button" onClick={() => setDeleteModalOpen(true)}>
          <DeleteIcon />
        </button>
      </div>

      <Modal
        isOpen={updateModalOpen}
        close={() => setUpdateModalOpen(false)}
        title={t("dependencies.updateSpecialization")}
      >
        <Card>
          <UpdateSpecialization specialization={specialization} />
        </Card>
      </Modal>

      <Modal isOpen={deleteModalOpen} close={() => setDeleteModalOpen(false)}>
        <Card>
          <DeleteSpecialization
            specialization={specialization}
            cancel={() => setDeleteModalOpen(false)}
          />
        </Card>
      </Modal>
    </>
  );
};
