import { create } from "zustand";

import { Nullable } from "@/utils/types";

import { getAllCurrencies } from "./Currencies/api";
import { CurrencyType } from "./Currencies/types";
import { getAllGenders } from "./Genders/api";
import { GenderType } from "./Genders/types";
import { getAllLocations } from "./Locations/api";
import { LocationType } from "./Locations/types";
import { getAllSpecializations } from "./Specializations/api";
import { SpecializationType } from "./Specializations/types";

interface DependenciesStore {
  currencies: Nullable<CurrencyType[]>;
  locations: Nullable<LocationType[]>;
  genders: Nullable<GenderType[]>;
  specializations: Nullable<SpecializationType[]>;
  fetchCurrencies: VoidFunction;
  fetchLocations: VoidFunction;
  fetchGenders: VoidFunction;
  fetchSpecializations: VoidFunction;
}

export const useDependenciesStore = create<DependenciesStore>((set) => ({
  currencies: null,
  locations: null,
  genders: null,
  specializations: null,
  fetchCurrencies: async () => {
    try {
      const res = await getAllCurrencies();
      set({ currencies: res.data.data });
    } catch (error) {
      console.log(error);
    }
  },
  fetchLocations: async () => {
    try {
      const res = await getAllLocations();
      set({ locations: res.data.data });
    } catch (error) {
      console.log(error);
    }
  },
  fetchGenders: async () => {
    try {
      const res = await getAllGenders();
      set({ genders: res.data.data });
    } catch (error) {
      console.log(error);
    }
  },
  fetchSpecializations: async () => {
    try {
      const res = await getAllSpecializations();
      set({ specializations: res.data.data });
    } catch (error) {
      console.log(error);
    }
  },
}));
