import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import {
  CreateCurrencyReq,
  CreateCurrencyRes,
  DeleteCurrenyReq,
  DeleteCurrenyRes,
  UpdateCurrencyReq,
  UpdateCurrencyRes,
  getAllCurrenciesRes,
} from "./types";

export const getAllCurrencies = async (): Promise<
  AxiosResponse<APIResponse<getAllCurrenciesRes>>
> => {
  return await axiosApi.get("/currencies/all");
};

export const createCurrency = async (
  req: CreateCurrencyReq
): Promise<AxiosResponse<APIResponse<CreateCurrencyRes>>> => {
  return await axiosApi.post("/currencies/add", req);
};

export const updateCurrency = async (
  req: UpdateCurrencyReq
): Promise<AxiosResponse<APIResponse<UpdateCurrencyRes>>> => {
  return await axiosApi.patch("/currencies/update", req);
};

export const deleteCurrency = async (
  req: DeleteCurrenyReq
): Promise<AxiosResponse<APIResponse<DeleteCurrenyRes>>> => {
  return await axiosApi.delete("/currencies/delete", {
    data: {
      ...req,
    },
  });
};
