import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import { SigninReq, SigninRes } from "./types";

export const signin = async (
  body: SigninReq
): Promise<AxiosResponse<APIResponse<SigninRes>>> => {
  return await axiosApi.post("/auth/signin", { ...body });
};
