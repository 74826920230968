import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { getDateTimeString } from "@/utils/date";

import cls from "./ClientCard.module.scss";
import { PersonIcon } from "./icons";

import { TelegramClient } from "../types";

type Props = {
  client: TelegramClient;
};

export const ClientCard: React.FC<Props> = ({ client }) => {
  const { t, i18n } = useTranslation();
  const lang = useMemo<string>(() => i18n.language, [i18n.language]);

  const clientName = useMemo(() => {
    if (client.first_name && client.last_name)
      return `${client.first_name} ${client.last_name}`;

    if (client.first_name && !client.last_name) return client.first_name;

    if (!client.first_name && client.last_name) return client.last_name;

    return t("clients.unknownName");
  }, [client.first_name, client.last_name, t]);

  return (
    <Card>
      <div className={cls.root}>
        <div className={cls.header}>
          <PersonIcon />
          <p>{clientName}</p>
        </div>

        <div className={cls.info}>
          <div className={cls.info_row}>
            <p className={cls.name}>{t("clients.username")}:</p>
            {client.username ? (
              <p className={cls.value}>
                <a
                  href={`https://t.me/${client.username}`}
                  target="_blank"
                  className={cls.telegram_link}
                  rel="noreferrer"
                >
                  {client.username}
                </a>
              </p>
            ) : (
              <p className={cls.unknown}>{t("clients.unknown")}</p>
            )}
          </div>

          <div className={cls.info_row}>
            <p className={cls.name}>{t("clients.phone")}:</p>
            {client.phone ? (
              <p className={cls.phone}>{client.phone}</p>
            ) : (
              <p className={cls.unknown}>{t("clients.unknown")}</p>
            )}
          </div>

          <div className={cls.info_row}>
            <p className={cls.name}>{t("clients.firstSession")}:</p>
            <p className={cls.value}>
              {getDateTimeString(new Date(client.createdAt), lang, t)}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
