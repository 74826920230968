import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { CloseIcon } from "./icons";
import cls from "./Modal.module.scss";

type Props = {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  title?: string;
};

export const Modal: React.FC<Props> = ({ isOpen, close, children, title }) => {
  const root = document.querySelector("body") as Element;
  const [waitAnimation, setWaitAnimation] = useState<boolean>(isOpen);

  useEffect(() => {
    let timer = 0;

    if (!isOpen) {
      timer = window.setTimeout(() => {
        setWaitAnimation(false);
      }, 300);
    } else {
      timer = window.setTimeout(() => {
        setWaitAnimation(true);
      }, 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  return root && (waitAnimation || isOpen)
    ? createPortal(
        <section
          className={cls.root}
          data-open={isOpen ? waitAnimation : isOpen}
        >
          <div className={cls.backdrop}>
            <button type="button" className={cls.close_btn} onClick={close} />
          </div>
          <div className={cls.content}>
            {title && (
              <header>
                <h2>{title}</h2>
                <button type="button" onClick={close}>
                  <CloseIcon />
                </button>
              </header>
            )}
            {children}
          </div>
        </section>,
        root
      )
    : null;
};
