import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { PageTitle } from "@/components/PageTitle/PageTitle";

import cls from "./Settings.module.scss";
import { SettingsNav } from "./SettingsNav/SettingsNav";

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.settings")} />
      <header>
        <SettingsNav />
      </header>
      <div className={cls.settings}>
        <Outlet />
      </div>
    </div>
  );
};
