import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { EmptyPhotoPlaceholder } from "@/components/EmptyPhotoPlaceholder/EmptyPhotoPlaceholder";
import { useAdvertName } from "@/hooks/useAdvertName";
import { useToast } from "@/hooks/useToast";
import { STATUS_COLORS, getImageSrc } from "@/utils/consts";
import { numberWithSpaces } from "@/utils/format";
import { AdvertStatusType } from "@/utils/types";

import cls from "./AdvertItem.module.scss";
import { NotesIcon, PhotosIcon } from "./icons";

import { onsellHorseAdvert, pauseHorseAdvert } from "../api";
import { HorseAdvert } from "../types";

type Props = {
  advert: HorseAdvert;
};

export const AdvertItem: React.FC<Props> = ({ advert }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useToast();

  const imgSrc = advert.photos && advert.photos[0] ? advert.photos[0] : "";
  const statusBackgroundColor = STATUS_COLORS[advert.status];
  const advertLink = `/dashboard/advert/${advert._id}`;

  const [isLoading, setLoading] = useState(false);

  const advertTitle = useAdvertName(advert);
  const advertSubtitle = useMemo(() => {
    const subtitleParts: string[] = [];

    advert.breed
      ? subtitleParts.push(advert.breed)
      : subtitleParts.push(t("advert.emptyBreed"));
    advert.location?.name
      ? subtitleParts.push(advert.location.name)
      : subtitleParts.push(t("advert.emptyLocation"));

    if (subtitleParts.length === 0)
      subtitleParts.push(t("advert.emptySubtitle"));

    return subtitleParts.join(t("common.commaSeparator"));
  }, [advert.breed, advert.location, t]);

  const specializations = useMemo(() => {
    const subtitleParts: string[] = [];

    advert.specializations.length
      ? advert.specializations.forEach((specialization) =>
          subtitleParts.push(specialization.name)
        )
      : subtitleParts.push(t("advert.emptySpecializations"));

    return subtitleParts.join(t("common.commaSeparator"));
  }, [advert.specializations, t]);

  const pauseAdvert = async () => {
    setLoading(true);
    try {
      await pauseHorseAdvert(advert._id);
      showSuccess(t("search.paused"));
      advert.status = AdvertStatusType.pause;
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onsellAdvert = async () => {
    setLoading(true);
    try {
      await onsellHorseAdvert(advert._id);
      showSuccess(t("search.onsell"));
      advert.status = AdvertStatusType.onsell;
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <li key={advert._id} className={cls.root}>
      <a className={cls.link} href={advertLink} />
      <Card>
        <div className={cls.advert}>
          <div className={cls.photo}>
            <div className={cls.img}>
              {imgSrc ? (
                <img src={getImageSrc(imgSrc)} />
              ) : (
                <EmptyPhotoPlaceholder />
              )}
            </div>

            <p
              style={{
                backgroundColor: statusBackgroundColor.bg,
                color: statusBackgroundColor.text,
              }}
              className={cls.status}
            >
              {t(`common.status.${advert.status}`)}
            </p>
            <div className={cls.actions}>
              <Button
                variant="secondary"
                onClick={() => navigate(`/dashboard/edit-advert/${advert._id}`)}
                disabled={isLoading}
                fullwidth
              >
                {t("common.edit")}
              </Button>
              {advert.status === AdvertStatusType.draft ||
              advert.status === AdvertStatusType.pause ? (
                <Button
                  variant="secondary"
                  onClick={onsellAdvert}
                  disabled={isLoading}
                  fullwidth
                >
                  {t("common.publish")}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={pauseAdvert}
                  disabled={isLoading}
                  fullwidth
                >
                  {t("common.pause")}
                </Button>
              )}
            </div>
          </div>

          <div className={cls.info}>
            <div className={cls.title}>
              <h1>{advertTitle}</h1>
              <p>{advertSubtitle}</p>
              <p>{specializations}</p>
            </div>
            <div className={cls.price}>
              <h2>
                {advert.price
                  ? `${numberWithSpaces(advert.price)} ${advert.currency?.symbol}`
                  : t("advert.emptyPrice")}
              </h2>
            </div>

            <div className={cls.description}>
              <p>{advert.description}</p>
            </div>
            {(advert.notes || advert.photosLink) && (
              <div className={cls.additional}>
                {advert.notes && (
                  <div className={cls.notes}>
                    <NotesIcon />
                    <p>{advert.notes}</p>
                  </div>
                )}

                {advert.photosLink && (
                  <a
                    className={cls.notes}
                    href={advert.photosLink || ""}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PhotosIcon />
                    <p>{t("create.photosLink")}</p>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    </li>
  );
};
