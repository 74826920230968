import cn from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import cls from "./SettingsNav.module.scss";

export const SettingsNav: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ul className={cls.root}>
      <li>
        <NavLink
          to={`dependencies`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <p>{t("nav.dependencies")}</p>
        </NavLink>
      </li>
    </ul>
  );
};
