import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { useAuthStore } from "@/modules/Auth/store";
import { useUserStore } from "@/modules/User/store";

import cls from "./Header.module.scss";
import { VendorCodeSearch } from "./VendorCodeSearch/VendorCodeSearch";

export const Header: React.FC = () => {
  const { t } = useTranslation();

  const user = useUserStore((s) => s.user);
  const resetAuth = useAuthStore((s) => s.resetAuth);

  return (
    <div className={cls.root}>
      <h1>{user?.name && t("common.hello", { name: user.name })}</h1>
      <VendorCodeSearch />
      <Button onClick={resetAuth}>{t("common.exit")}</Button>
    </div>
  );
};
