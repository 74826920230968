export const HEADERS = {
  Accept: "application/json",
};

export function makeRequestHeaders(access_token?: string) {
  if (access_token) {
    return {
      ...HEADERS,
      Authorization: `Bearer ${access_token}`,
    };
  }

  return { ...HEADERS };
}
