import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import { DeleteIcon, EditIcon } from "./icons";
import cls from "./Locations.module.scss";

import { DeleteLocation } from "../Locations/DeleteLocation";
import { LocationType } from "../Locations/types";
import { UpdateLocation } from "../Locations/UpdateLocation";

type Props = {
  location: LocationType;
};

export const LocationActions: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <div className={cls.actions}>
        <button type="button" onClick={() => setUpdateModalOpen(true)}>
          <EditIcon />
        </button>
        <button type="button" onClick={() => setDeleteModalOpen(true)}>
          <DeleteIcon />
        </button>
      </div>

      <Modal
        isOpen={updateModalOpen}
        close={() => setUpdateModalOpen(false)}
        title={t("dependencies.updateLocation")}
      >
        <Card>
          <UpdateLocation location={location} />
        </Card>
      </Modal>

      <Modal isOpen={deleteModalOpen} close={() => setDeleteModalOpen(false)}>
        <Card>
          <DeleteLocation
            location={location}
            cancel={() => setDeleteModalOpen(false)}
          />
        </Card>
      </Modal>
    </>
  );
};
