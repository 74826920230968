/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PaginationPages } from "@/components/PaginationPages/PaginationPages";
import { Spinner } from "@/components/Spinner/Spinner";
import { useFetchWithParams } from "@/hooks/useFetch";
import { usePagination } from "@/hooks/usePagination";

import { getPaginatedClients } from "./api";
import { ClientCard } from "./ClientCard/ClientCard";
import cls from "./ClientsList.module.scss";
import { CLIENTS_PAGINATION_TAKE_OPTIONS, CLIENTS_TAKE_COUNT } from "./consts";
import { TelegramClient } from "./types";
import { PageTitle } from "@/components/PageTitle/PageTitle";

export const ClientsList: React.FC = () => {
  const { t } = useTranslation();

  const [takeCount, setTakeCount] = useState(CLIENTS_TAKE_COUNT);
  const [clientsList, setClientsList] = useState<TelegramClient[]>([]);

  const {
    page,
    take,
    onNextPage,
    onPrevPage,
    handleMetaData,
    hasNextPage,
    hasPrevPage,
    itemsCount,
  } = usePagination(takeCount);

  const params = useMemo(() => ({ page, take }), [page, take]);
  const { data, loading } = useFetchWithParams(getPaginatedClients, params);

  const getPagesCount = () => {
    if (!data.data) return;

    handleMetaData(data.meta);
    setClientsList(data.data);
  };

  useEffect(() => {
    getPagesCount();
  }, [data]);

  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.clients")} />

      {loading && (
        <div className={cls.placeholder}>
          <Spinner />
        </div>
      )}

      {!loading && (
        <div className={cls.clients}>
          <h1>{t("search.clients")}</h1>
          <ul className={cls.list}>
            {clientsList.map((client) => (
              <li key={client._id}>
                <ClientCard client={client} />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={cls.pagination}>
        <PaginationPages
          currentPage={page}
          itemsCount={itemsCount}
          takeCount={take}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          setTakeCount={setTakeCount}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          paginationOptions={CLIENTS_PAGINATION_TAKE_OPTIONS}
        />
      </div>
    </div>
  );
};
