import { useEffect, useState } from "react";

import { DEFAULT_TAKE_COUNT } from "@/utils/consts";
import { PaginationResponse } from "@/utils/types";

const FIRST_PAGE = 1;

export const usePagination = (take: number = DEFAULT_TAKE_COUNT) => {
  const [page, setPage] = useState(FIRST_PAGE);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);

  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const onPrevPage = () => {
    setPage((prev) => (prev === 1 ? 1 : prev - 1));
  };

  const handleMetaData = (meta: PaginationResponse["meta"]) => {
    setPagesCount(meta.pageCount);
    setItemsCount(meta.itemCount);
    setHasNextPage(meta.hasNextPage);
    setHasPrevPage(meta.hasPreviousPage);
  };

  useEffect(() => {
    setPage(FIRST_PAGE);
  }, [take]);

  return {
    page,
    take,
    onNextPage,
    onPrevPage,
    handleMetaData,
    hasNextPage,
    hasPrevPage,
    pagesCount,
    itemsCount,
  };
};
