import { TFunction } from "i18next";

export function localeToICU(lang: string): string {
  if (lang === "ru") return "ru_RU";
  if (!lang.includes("-")) return lang;

  const [first, second] = lang.split("-");
  return `${first.toLowerCase()}_${second.toUpperCase()}`;
}

export function onlyNumbers(value: string, allowedChars = ""): string {
  const reg = new RegExp(`[^0-9${allowedChars}]`, "g");
  return value.trim().replace(reg, "");
}

export function numberWithSpaces(x: number): string {
  const parts = x.toString().split(".");
  parts[0] = new Intl.NumberFormat("ru").format(Number(parts[0]));
  return parts.join(".");
}

export function maxLength(str: string, max: number): string {
  if (str.length > max) {
    return str.slice(0, max);
  }

  return str;
}

export function replaceBreaksWithSpaces(str: string): string {
  return str.replace(/ {1,}/g, " ");
}

export function removeEmojis(str: string) {
  return str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    )
    .replace(/\s+/g, " ")
    .trim();
}

export function bytesToSize(bytes: number, decimals?: number): string {
  if (bytes === 0) return "0 bytes";

  const decimalsValue = decimals || 2;

  const k = 1024;
  const dm = decimalsValue < 0 ? 0 : decimalsValue;
  const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeEmptyParams(params: Record<string, any>) {
  const paramsCopy = { ...params };
  const keysToDelete: string[] = [];

  Object.keys(paramsCopy).forEach((key) => {
    if (
      !paramsCopy[key] ||
      (Array.isArray(paramsCopy[key]) &&
        paramsCopy[key].every((val: unknown) => !val))
    ) {
      keysToDelete.push(key);
    }
  });

  keysToDelete.forEach((key) => delete paramsCopy[key]);

  return paramsCopy;
}

export function millionToReadable(number: number, t: TFunction) {
  return `${number / 1000000} ${t("common.million")}`;
}
