import { Currencies } from "./Currencies/Currencies";
import cls from "./Dependencies.module.scss";
import { Locations } from "./Locations/Locations";
import { Specializations } from "./Specializations/Specializations";

export const Dependencies: React.FC = () => {
  return (
    <div className={cls.root}>
      <Currencies />
      <Locations />
      <Specializations />
    </div>
  );
};
