import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { OptionI } from "@/utils/types";

import cls from "./Dropdown.module.scss";

type Props = {
  options: OptionI[];
  onSelect: (option: OptionI) => void;
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
};

export const Dropdown: React.FC<Props> = ({
  isOpen,
  close,
  options,
  onSelect,
  children,
}) => {
  const { t } = useTranslation();
  const sectionRef = useRef<HTMLDivElement>(null);
  const [waitAnimation, setWaitAnimation] = useState<boolean>(isOpen);

  useEffect(() => {
    let timer = 0;

    if (!isOpen) {
      timer = window.setTimeout(() => {
        setWaitAnimation(false);
      }, 300);
    } else {
      timer = window.setTimeout(() => {
        setWaitAnimation(true);
      }, 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  const onWindowClick = useCallback(
    (e: MouseEvent) => {
      if (!sectionRef.current) return;

      const coords = sectionRef.current.getBoundingClientRect();
      const { left: x, top: y, width, height } = coords;
      const clickX = e.x;
      const clickY = e.y;

      if (clickX > x && clickX < x + width && clickY > y && clickY < y + height)
        return;

      if (waitAnimation) close();
    },
    [close, waitAnimation]
  );

  useEffect(() => {
    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, [onWindowClick]);

  return waitAnimation || isOpen ? (
    <section
      ref={sectionRef}
      className={cls.root}
      data-open={isOpen ? waitAnimation : isOpen}
    >
      <ul className={cls.content}>
        {children && <li className={cls.list_header}>{children}</li>}

        {options.length === 0 && (
          <li className={cls.empty}>
            <p>{t("common.notfound")}</p>
          </li>
        )}

        {options.length !== 0 &&
          options.map((option) => {
            return (
              <li key={option.id}>
                <p>{option.text}</p>
                <button type="button" onClick={() => onSelect(option)} />
              </li>
            );
          })}
      </ul>
    </section>
  ) : null;
};
