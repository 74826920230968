import cn from "classnames";
import { forwardRef } from "react";

import cls from "./Input.module.scss";

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type Props = {
  value?: InputProps["value"];
  onChange: InputProps["onChange"];
  placeholder?: InputProps["placeholder"];
  disabled?: InputProps["disabled"];
  type?: InputProps["type"];
  error?: boolean;
  fullwidth?: boolean;
  label?: string;
  readOnly?: boolean;
  suffix?: React.ReactNode;
  className?: string;
  onKeyDown?: InputProps["onKeyDown"];
};

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, suffix } = props;

  return (
    <div className={cls.root}>
      {label && (
        <div className={cls.label}>
          <p>{label}</p>
        </div>
      )}

      <div
        className={cn(cls.input, props.className, {
          [cls.error]: props.error,
          [cls.fullwidth]: props.fullwidth,
        })}
      >
        <input ref={ref} {...props} />
        {suffix && <div className={cls.suffix}>{suffix}</div>}
      </div>
    </div>
  );
});

Input.displayName = "Input";
