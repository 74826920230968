import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import { CreateCurrency } from "./CreateCurrency";
import cls from "./Currencies.module.scss";
import { CurrenciesTable } from "./CurrenciesTable";

import { useDependenciesStore } from "../store";

export const Currencies: React.FC = () => {
  const { t } = useTranslation();
  const data = useDependenciesStore((s) => s.currencies);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Card>
        <h2 className={cls.title}>{t("dependencies.currency")}</h2>
        <p className={cls.description}>
          {t("dependencies.currencyDescription")}
        </p>
        {data && <CurrenciesTable currencies={data} />}
        <Button onClick={() => setModalOpen(true)}>{t("common.add")}</Button>
      </Card>

      <Modal
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        title={t("dependencies.addCurrency")}
      >
        <Card>
          <CreateCurrency closeModal={() => setModalOpen(false)} />
        </Card>
      </Modal>
    </>
  );
};
