import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGenders } from "@/modules/Settings/Dependencies/Genders/hooks";
import { useSpecializations } from "@/modules/Settings/Dependencies/Specializations/hooks";
import { millionToReadable } from "@/utils/format";

import cls from "./SessionFilters.module.scss";

import { Session } from "../types";

type Props = {
  filters: Session["filters"];
};

export const SessionFilters: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation();
  const genders = useGenders();
  const specializations = useSpecializations();
  const { price, birthYear, gender, height, specialization } = filters;

  const genderName = useMemo(() => {
    const findedGender = genders.find((g) => g.id === gender.value);

    return findedGender ? findedGender.text : t("sessions.anyGender");
  }, [gender.value, genders, t]);

  const priceValue = useMemo(() => {
    return price.from
      ? `${millionToReadable(price.from || 0, t)} - ${millionToReadable(price.to || 0, t)}`
      : `${t("common.to")} ${millionToReadable(price.to || 0, t)}`;
  }, [price.from, price.to, t]);

  const specializationName = useMemo(() => {
    const findedSpecialization = specializations.find(
      (s) => s.id === specialization.value
    );

    return findedSpecialization
      ? findedSpecialization.text
      : t("sessions.anySpecialization");
  }, [specialization.value, specializations, t]);

  return (
    <div className={cls.root}>
      <div className={cls.filter}>
        <p className={cls.name}>{t("common.price")}</p>
        <p className={cls.value}>{priceValue}</p>
      </div>

      <div className={cls.filter}>
        <p className={cls.name}>{t("common.birthYear")}</p>
        <p className={cls.value}>
          {birthYear.from} {t("common.shortYear")} - {birthYear.to}{" "}
          {t("common.shortYear")}
        </p>
      </div>

      <div className={cls.filter}>
        <p className={cls.name}>{t("common.gender")}</p>
        <p className={cls.value}>{genderName}</p>
      </div>

      <div className={cls.filter}>
        <p className={cls.name}>{t("common.height")}</p>
        <p className={cls.value}>
          {height.from} - {height.to}
        </p>
      </div>

      <div className={cls.filter}>
        <p className={cls.name}>{t("common.specialization")}</p>
        <p className={cls.value}>{specializationName}</p>
      </div>
    </div>
  );
};
