import { useMemo } from "react";

import { useDependenciesStore } from "../store";

export const useGenders = () => {
  const genders = useDependenciesStore((s) => s.genders);
  const gendersOptions = useMemo(
    () =>
      genders
        ? genders.map((gender) => ({
            id: gender._id,
            text: gender.name,
          }))
        : [],
    [genders]
  );

  return gendersOptions;
};
