import cn from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  ClientsIcon,
  CreateIcon,
  ListIcon,
  SessionsIcon,
  SettingsIcon,
} from "./icons";
import cls from "./Nav.module.scss";

export const Nav: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ul className={cls.root}>
      <li>
        <NavLink
          to={`/dashboard/create-advert`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <CreateIcon />
          <p>{t("nav.createAdvert")}</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={`/dashboard/sell-list`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <ListIcon />
          <p>{t("nav.sellList")}</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={`/dashboard/sessions/list`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <SessionsIcon />
          <p>{t("nav.sessions")}</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={`/dashboard/clients/list`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <ClientsIcon />
          <p>{t("nav.clients")}</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to={`/dashboard/settings`}
          className={({ isActive }) => cn({ [cls.active]: isActive })}
        >
          <SettingsIcon />
          <p>{t("nav.settings")}</p>
        </NavLink>
      </li>
    </ul>
  );
};
