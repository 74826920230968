import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { getDateTimeString } from "@/utils/date";

import { PersonIcon, TimeIcon } from "./icons";
import cls from "./SessionCard.module.scss";

import { SessionAdverts } from "../SessionAdverts/SessionAdverts";
import { SessionFilters } from "../SessionFilters/SessionFilters";
import { Session } from "../types";

type Props = {
  session: Session;
};

export const SessionCard: React.FC<Props> = ({ session }) => {
  const { t, i18n } = useTranslation();
  const lang = useMemo<string>(() => i18n.language, [i18n.language]);

  const clientName = useMemo(() => {
    const client = session.client;

    if (!client) return "";

    return client.first_name && client.last_name
      ? `${client.first_name} ${client.last_name}`
      : client.first_name
        ? client.first_name
        : client.last_name
          ? client.last_name
          : client.username
            ? client.username
            : client.telegramId;
  }, [session]);

  return (
    <Card>
      <div className={cls.root}>
        <div className={cls.header}>
          <div className={cls.user}>
            <PersonIcon />
            <p>{clientName}</p>
          </div>

          <div className={cls.created}>
            <TimeIcon />
            <p>{getDateTimeString(new Date(session.createdAt), lang, t)}</p>
          </div>
        </div>

        <div className={cls.divider} />

        <div className={cls.filters}>
          <SessionFilters filters={session.filters} />
        </div>

        <div className={cls.divider} />

        <div className={cls.adverts}>
          {session.adverts.length !== 0 && (
            <SessionAdverts adverts={session.adverts} />
          )}

          {session.adverts.length === 0 && (
            <p className={cls.no_matches}>{t("sessions.noAdvertMatches")}</p>
          )}
        </div>
      </div>
    </Card>
  );
};
