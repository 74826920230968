import cls from "./Spinner.module.scss";

interface PropsI {
  size?: number;
  color?: string;
  centered?: boolean;
}

export function Spinner({
  size = 40,
  color = "var(--clr-primary)",
  centered,
}: PropsI): JSX.Element {
  const svg = (
    <svg
      style={{ "--color": color } as React.CSSProperties}
      width={size}
      height={size}
      className={cls.spinner}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );

  return centered ? <div className={cls.container}>{svg}</div> : svg;
}

Spinner.displayName = "Spinner";
