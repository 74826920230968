export function EditIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24234 8.89998C6.62102 10.061 7.71252 10.9 8.99998 10.9C10.2874 10.9 11.3789 10.061 11.7576 8.89998C14.5051 8.89997 17.2525 8.89998 20 8.89998C20.497 8.89998 20.9 8.49703 20.9 7.99998C20.9 7.50292 20.497 7.09998 20 7.09998C17.2525 7.09998 14.5051 7.09998 11.7576 7.09998C11.7479 7.06961 11.7609 7.11007 11.7576 7.09998C11.3789 5.93893 10.2874 5.09998 8.99998 5.09998C7.71252 5.09998 6.62102 5.93894 6.24234 7.09998C5.49489 7.09998 4.74743 7.09998 3.99998 7.09998C3.50292 7.09998 3.09998 7.50292 3.09998 7.99998C3.09998 8.49703 3.50292 8.89998 3.99998 8.89998C4.74743 8.89998 5.49489 8.89997 6.24234 8.89998ZM8.99998 6.89998C8.39246 6.89998 7.89998 7.39246 7.89998 7.99998C7.89998 8.60749 8.39246 9.09998 8.99998 9.09998C9.60749 9.09998 10.1 8.60749 10.1 7.99998C10.1 7.39246 9.60749 6.89998 8.99998 6.89998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09998 16C3.09998 15.5029 3.50292 15.1 3.99998 15.1C6.74743 15.1 9.49489 15.1 12.2423 15.1C12.621 13.9389 13.7125 13.1 15 13.1C16.2874 13.1 17.3789 13.9389 17.7576 15.1C17.7576 15.1 19.4786 15.1 20 15.1C20.497 15.1 20.9 15.5029 20.9 16C20.9 16.497 20.497 16.9 20 16.9C19.7777 16.9 17.7576 16.9 17.7576 16.9C17.3789 18.061 16.2874 18.9 15 18.9C13.7125 18.9 12.621 18.061 12.2423 16.9C9.49489 16.9 6.74743 16.9 3.99998 16.9C3.50292 16.9 3.09998 16.497 3.09998 16ZM13.9 16C13.9 15.3925 14.3925 14.9 15 14.9C15.6075 14.9 16.1 15.3925 16.1 16C16.1 16.6075 15.6075 17.1 15 17.1C14.3925 17.1 13.9 16.6075 13.9 16Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1C14.1927 1 15.6085 2.09609 15.9851 4.13669C15.989 4.15797 15.9966 4.17922 15.9995 4.2004L21.1 4.2C21.5971 4.2 22 4.60294 22 5.1C22 5.55882 21.6567 5.93745 21.2129 5.99299L21.1 6H19.999L18.6192 18.4216C18.4619 19.8309 17.3118 20.9106 15.9131 20.9947L15.7371 21H8.26294C6.78576 21 5.54463 19.8897 5.38082 18.4216L3.995 6H2.9C2.40294 6 2 5.59706 2 5.1C2 4.64118 2.34334 4.26255 2.78711 4.20701L2.9 4.2L8.01227 4.20104C8.00978 4.20069 8.00727 4.20034 8.00476 4.2C8.00722 4.1766 8.0106 4.16021 8.01494 4.13669C8.39148 2.09609 9.80726 1 12 1ZM18.193 6H5.804L7.16972 18.222C7.22298 18.6993 7.57646 19.0769 8.02922 19.175L8.14436 19.1937L8.26294 19.2H15.7371C16.2974 19.2 16.7681 18.7788 16.8303 18.222L18.193 6ZM12 7.5C12.4588 7.5 12.8375 7.84334 12.893 8.28711L12.9 8.4V16.6C12.9 17.0971 12.4971 17.5 12 17.5C11.5412 17.5 11.1625 17.1567 11.107 16.7129L11.1 16.6V8.4C11.1 7.90294 11.5029 7.5 12 7.5ZM15.4548 7.50167C15.9127 7.52959 16.2698 7.89534 16.2982 8.34166L16.2983 8.45478L15.7983 16.6548C15.7681 17.1509 15.3414 17.5286 14.8452 17.4983C14.3873 17.4704 14.0302 17.1047 14.0018 16.6583L14.0017 16.5452L14.5017 8.34522C14.5319 7.84909 14.9586 7.47142 15.4548 7.50167ZM9.48446 8.23297L9.49833 8.34522L9.99833 16.5452C10.0286 17.0414 9.65091 17.4681 9.15478 17.4983C8.69681 17.5263 8.29798 17.2066 8.21554 16.767L8.20167 16.6548L7.70167 8.45478C7.67142 7.95864 8.04909 7.53192 8.54522 7.50167C9.00319 7.47374 9.40202 7.7934 9.48446 8.23297ZM12 2.8C10.7578 2.8 10.1075 3.22659 9.84486 4.19782L9.834 4.2L14.1548 4.20087L14.1554 4.19877C13.8929 3.22686 13.2426 2.8 12 2.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
