import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { getCookie, setCookie } from "@/utils/cookie";

const version = "-v0.0.6";
export const APP_LANG_NAME = "hs-app-lang";
export const LANGS_MAP = {
  en: "en",
  ru: "ru",
};
export const LANGS = Object.values(LANGS_MAP);
export const DEFAULT_LANG = LANGS_MAP.ru;
export const FALLBACK_LANG = LANGS_MAP.en;

export function getBrowserLang(): string {
  return window.navigator.language.split("-")[0];
}

export function isValidLang(lang: string): boolean {
  return !!lang && LANGS.includes(lang);
}

export function getInitialLanguage(): string {
  const cookieValue = getCookie(APP_LANG_NAME);

  if (cookieValue) {
    const parsed = JSON.parse(cookieValue);
    if (isValidLang(parsed)) return parsed;
  }

  return DEFAULT_LANG;
}

export function saveLangInCookie(lang: string): void {
  setCookie(APP_LANG_NAME, JSON.stringify(lang));
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: `/locales${version}/{{lng}}/{{ns}}.json`,
    },
    lng: getInitialLanguage(),
    fallbackLng: FALLBACK_LANG,
    supportedLngs: LANGS,
    ns: "common",
    cleanCode: true,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
