import { useMemo } from "react";

import { useDebounce } from "@/hooks/useDebounce";

import { useSearchStore } from "./store";

import { GetFilteredHorsesAdvertsReq } from "../SellList/types";

const SEARCH_STORE_DEBOUNCE = 500;

export const useSearchStoreParams = () => {
  const searchStore = useSearchStore((s) => s);
  const debouncedStore = useDebounce(searchStore, SEARCH_STORE_DEBOUNCE);

  const params = useMemo<GetFilteredHorsesAdvertsReq>(() => {
    return {
      vendorCode: debouncedStore.vendorCode
        ? Number(debouncedStore.vendorCode)
        : undefined,
      priceFrom: debouncedStore.priceFrom
        ? Number(debouncedStore.priceFrom)
        : undefined,
      priceTo: debouncedStore.priceTo
        ? Number(debouncedStore.priceTo)
        : undefined,
      gender: debouncedStore.gender || undefined,
      birthYearFrom: debouncedStore.birthYearFrom
        ? Number(debouncedStore.birthYearFrom)
        : undefined,
      birthYearTo: debouncedStore.birthYearTo
        ? Number(debouncedStore.birthYearTo)
        : undefined,
      location: debouncedStore.location || undefined,
      status: debouncedStore.status || undefined,
    };
  }, [debouncedStore]);

  return params;
};
