import { useMemo } from "react";

import { useDependenciesStore } from "../store";

export const useLocations = () => {
  const locations = useDependenciesStore((s) => s.locations);
  const locationsOptions = useMemo(
    () =>
      locations
        ? locations.map((location) => ({
            id: location._id,
            text: location.name,
          }))
        : [],
    [locations]
  );

  return locationsOptions;
};
