/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { AuthState, useAuthStore } from "@/modules/Auth/store";
import { useUserFetch } from "@/modules/User/store";

import cls from "./MainLayout.module.scss";

import { Nav } from "../DashboardLayout/Nav/Nav";
import { useDependenciesStore } from "../Settings/Dependencies/store";

export const MainLayout: React.FC = () => {
  const navigate = useNavigate();

  const authState = useAuthStore((s) => s.authState);
  const getAccessTokenFromCookie = useAuthStore(
    (s) => s.getAccessTokenFromCookie
  );

  const fetchUser = useUserFetch();
  const fetchCurrencies = useDependenciesStore((s) => s.fetchCurrencies);
  const fetchGenders = useDependenciesStore((s) => s.fetchGenders);
  const fetchLocations = useDependenciesStore((s) => s.fetchLocations);
  const fetchSpecializations = useDependenciesStore(
    (s) => s.fetchSpecializations
  );

  useEffect(() => {
    if (authState === AuthState.logged) {
      fetchUser();
    } else if (authState === AuthState.unregistered) {
      navigate("/signin");
    }
  }, [authState]);

  useEffect(() => {
    getAccessTokenFromCookie();
    fetchCurrencies();
    fetchGenders();
    fetchLocations();
    fetchSpecializations();
  }, [authState]);

  return (
    <>
      {authState === AuthState.logged && (
        <div className={cls.mob_nav}>
          <Nav />
        </div>
      )}
      <div className={cls.root}>
        <Outlet />
      </div>
    </>
  );
};
