import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@/components/Grid/Grid";
import { TableData, useTableData } from "@/hooks/useTableData";

import { SpecializationActions } from "./SpecializationActions";
import { SpecializationType } from "./types";

type Props = {
  specializations: SpecializationType[];
};

type MappedData = Omit<SpecializationType, "_id"> & { actions: JSX.Element };

export const SpecializationsTable: React.FC<Props> = ({ specializations }) => {
  const { t } = useTranslation();

  const mappedData = useMemo<MappedData[]>(
    () =>
      specializations.map((specialization) => {
        return {
          ...specialization,
          actions: <SpecializationActions specialization={specialization} />,
        };
      }),
    [specializations]
  );

  const tableData: TableData<MappedData> = {
    data: mappedData,
    columns: [
      {
        title: t("specialization.name"),
        accessor: "name",
      },
      {
        title: t("currency.actions"),
        accessor: "actions",
      },
    ],
  };

  const { data, columns } = useTableData(tableData);

  return (
    <>
      <Grid data={data} columns={columns} />
    </>
  );
};
