/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { useToast } from "@/hooks/useToast";

import { createSpecialization } from "./api";
import cls from "./Specializations.module.scss";

import { useDependenciesStore } from "../store";

type Props = {
  closeModal?: () => void;
};

export const CreateSpecialization: React.FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { showError } = useToast();

  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchSpecializations = useDependenciesStore(
    (s) => s.fetchSpecializations
  );

  const isValid = !!name;

  const onSpecializationCreate = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      const req = {
        name,
      };
      await createSpecialization(req);
      fetchSpecializations();
      closeModal && closeModal();
    } catch (err) {
      const error = err as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        showError(error);
      }
      setError(true);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isError && !errorMessage) return;

    setError(false);
    setErrorMessage("");
  }, [name]);

  return (
    <div className={cls.create_root}>
      {errorMessage && <p className={cls.error}>{errorMessage}</p>}
      <Input
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        placeholder={t("specialization.name")}
        error={isError}
      />
      <Button onClick={onSpecializationCreate} disabled={loading || !isValid}>
        {t("common.add")}
      </Button>
    </div>
  );
};
