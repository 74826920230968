import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { Advert } from "@/modules/Advert/Advert";
import { Signin } from "@/modules/Auth/Signin/Signin";
import { ClientsList } from "@/modules/Clients/ClientsList";
import { CreateAdvert } from "@/modules/CreateAdvert/CreateAdvert";
import { DashboardLayout } from "@/modules/DashboardLayout/DashboardLayout";
import { EditAdvert } from "@/modules/EditAdvert/EditAdvert";
import { MainLayout } from "@/modules/MainLayout/MainLayout";
import { SellList } from "@/modules/SellList/SellList";
import { SessionsList } from "@/modules/Sessions/SessionsList";
import { Dependencies } from "@/modules/Settings/Dependencies/Dependencies";
import { Settings } from "@/modules/Settings/Settings";

export const AppRouter: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/dashboard/sell-list"} />,
        },
        {
          path: "/dashboard",
          element: <DashboardLayout />,
          children: [
            {
              index: true,
              element: <Navigate to={"sell-list"} />,
            },
            { path: "sell-list", element: <SellList /> },
            {
              path: "settings",
              element: <Settings />,
              children: [
                {
                  index: true,
                  element: <Navigate to={"dependencies"} />,
                },
                {
                  path: "dependencies",
                  element: <Dependencies />,
                },
              ],
            },
            {
              path: "create-advert",
              element: <CreateAdvert />,
            },
            {
              path: "edit-advert/:advertId",
              element: <EditAdvert />,
            },
            {
              path: "advert/:advertId",
              element: <Advert />,
            },
            {
              path: "sessions/list",
              element: <SessionsList />,
            },
            {
              path: "clients/list",
              element: <ClientsList />,
            },
          ],
        },
        {
          path: "/signin",
          element: <Signin />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
