import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import { CreateSpecialization } from "./CreateSpecialization";
import cls from "./Specializations.module.scss";
import { SpecializationsTable } from "./SpecializationsTable";

import { useDependenciesStore } from "../store";

export const Specializations: React.FC = () => {
  const { t } = useTranslation();
  const data = useDependenciesStore((s) => s.specializations);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Card>
        <h2>{t("dependencies.specializations")}</h2>
        <p className={cls.description}>
          {t("dependencies.specializationsDescription")}
        </p>
        {data && <SpecializationsTable specializations={data} />}
        <Button onClick={() => setModalOpen(true)}>{t("common.add")}</Button>
      </Card>

      <Modal
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        title={t("dependencies.addSpecialization")}
      >
        <Card>
          <CreateSpecialization closeModal={() => setModalOpen(false)} />
        </Card>
      </Modal>
    </>
  );
};
