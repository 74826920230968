import { useTranslation } from "react-i18next";

import { Input } from "@/components/Input/Input";
import { Select } from "@/components/Select/Select";
import { useSearchStore } from "@/modules/Search/store";
import { useGenders } from "@/modules/Settings/Dependencies/Genders/hooks";
import { useLocations } from "@/modules/Settings/Dependencies/Locations/hooks";

import cls from "./FiltersList.module.scss";

export const FiltersList: React.FC = () => {
  const { t } = useTranslation();

  const gendersOptions = useGenders();
  const locationsOptions = useLocations();

  const vendorCode = useSearchStore((s) => s.vendorCode);
  const setVendorCode = useSearchStore((s) => s.setVendorCode);
  const priceFrom = useSearchStore((s) => s.priceFrom);
  const setPriceFrom = useSearchStore((s) => s.setPriceFrom);
  const priceTo = useSearchStore((s) => s.priceTo);
  const setPriceTo = useSearchStore((s) => s.setPriceTo);
  const gender = useSearchStore((s) => s.gender);
  const setGender = useSearchStore((s) => s.setGender);
  const birthYearFrom = useSearchStore((s) => s.birthYearFrom);
  const setBirthYearFrom = useSearchStore((s) => s.setBirthYearFrom);
  const birthYearTo = useSearchStore((s) => s.birthYearTo);
  const setBirthYearTo = useSearchStore((s) => s.setBirthYearTo);
  const location = useSearchStore((s) => s.location);
  const setLocation = useSearchStore((s) => s.setLocation);

  return (
    <div className={cls.root}>
      <div className={cls.filters}>
        <Input
          value={vendorCode ? vendorCode.toString() : ""}
          onChange={(e) => setVendorCode(e.currentTarget.value)}
          placeholder={t("common.vendorCode")}
        />
        <Input
          value={priceFrom ? priceFrom.toString() : ""}
          onChange={(e) => setPriceFrom(e.currentTarget.value)}
          placeholder={t("search.priceFrom")}
        />
        <Input
          value={priceTo ? priceTo.toString() : ""}
          onChange={(e) => setPriceTo(e.currentTarget.value)}
          placeholder={t("search.priceTo")}
        />
        <Select
          options={gendersOptions}
          value={gender || ""}
          onChange={(e) => setGender(e.currentTarget.value)}
          placeholder={t("common.gender")}
        />
        <Input
          value={birthYearFrom ? birthYearFrom.toString() : ""}
          onChange={(e) => setBirthYearFrom(e.currentTarget.value)}
          placeholder={t("search.birthYearFrom")}
        />
        <Input
          value={birthYearTo ? birthYearTo.toString() : ""}
          onChange={(e) => setBirthYearTo(e.currentTarget.value)}
          placeholder={t("search.birthYearTo")}
        />
        <Select
          options={locationsOptions}
          value={location || ""}
          onChange={(e) => setLocation(e.currentTarget.value)}
          placeholder={t("common.location")}
        />
      </div>
    </div>
  );
};
