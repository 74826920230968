import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";
import { HorseAdvert } from "@/modules/SellList/types";

export const getAdvertByVendorCode = async (
  vendorCode: string
): Promise<AxiosResponse<APIResponse<HorseAdvert[]>>> => {
  return await axiosApi.get(`/horses/getshort/${vendorCode}`);
};
