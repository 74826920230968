import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";
import { PaginationReq } from "@/utils/types";

import { GetPaginatedSessionsRes } from "./types";

export const getPaginatedSessions = async (
  params?: PaginationReq
): Promise<AxiosResponse<APIResponse<GetPaginatedSessionsRes>>> => {
  return await axiosApi.get("/sessions/list", { params });
};
