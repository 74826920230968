import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { PageTitle } from "@/components/PageTitle/PageTitle";
import { Spinner } from "@/components/Spinner/Spinner";
import { useFetch, useFetchWithParams } from "@/hooks/useFetch";

import { AdvertItem } from "./AdvertItem/AdvertItem";
import { getAdvertsStatuses, getFilteredHorsesAdverts } from "./api";
import { FiltersList } from "./FiltersList/FiltersList";
import cls from "./SellList.module.scss";
import { StatusFilter } from "./StatusFilter/StatusFilter";

import { useSearchStoreParams } from "../Search/hooks";

export const SellList: React.FC = () => {
  const { t } = useTranslation();
  const params = useSearchStoreParams();
  const { data: adverts, loading: advertsLoading } = useFetchWithParams(
    getFilteredHorsesAdverts,
    params
  );
  const { data: statuses } = useFetch(getAdvertsStatuses);

  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.sellList")} />
      <div className={cls.filters}>
        <h1>{t("search.filters")}</h1>
        <Card>
          <FiltersList />
        </Card>
      </div>
      <div className={cls.adverts}>
        <h1>
          {t("search.adverts")}
          {adverts && adverts.length ? ` - ${adverts.length}` : ""}
        </h1>
        <StatusFilter statuses={statuses} />
        {adverts && !advertsLoading && (
          <ul className={cls.list}>
            {adverts.map((advert) => {
              return <AdvertItem key={advert._id} advert={advert} />;
            })}
          </ul>
        )}

        {advertsLoading && (
          <div className={cls.spinner}>
            <Spinner color="var(--thm-grey-4)" />
          </div>
        )}
      </div>
    </div>
  );
};
