import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import {
  AddHorseReq,
  AddHorseRes,
  UpdateHorseReq,
  UploadImageRes,
} from "./types";

export const uploadImage = async (
  formData: FormData
): Promise<AxiosResponse<APIResponse<UploadImageRes>>> => {
  return await axiosApi.post("/filebox/image/upload", formData);
};

export const addHorsePublic = async (
  req: AddHorseReq
): Promise<AxiosResponse<APIResponse<AddHorseRes>>> => {
  return await axiosApi.post("/horses/add/public", req);
};

export const addHorseDraft = async (
  req: AddHorseReq
): Promise<AxiosResponse<APIResponse<AddHorseRes>>> => {
  return await axiosApi.post("/horses/add/draft", req);
};

export const updateHorse = async (
  req: UpdateHorseReq
): Promise<AxiosResponse<APIResponse<AddHorseRes>>> => {
  return await axiosApi.patch("/horses/update", req);
};
