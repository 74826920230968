import cn from "classnames";

import cls from "./Button.module.scss";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type Props = {
  onClick: ButtonProps["onClick"];
  disabled?: ButtonProps["disabled"];
  children: React.ReactNode;
  variant?: "primary" | "danger" | "secondary";
  fullwidth?: boolean;
};

export const Button: React.FC<Props> = ({
  onClick,
  disabled,
  children,
  variant = "primary",
  fullwidth,
}) => {
  return (
    <button
      type="button"
      className={cn(cls.root, {
        [cls.primary]: variant === "primary",
        [cls.danger]: variant === "danger",
        [cls.secondary]: variant === "secondary",
        [cls.fullwidth]: fullwidth,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
