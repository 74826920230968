import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import { CreateLocation } from "./CreateLocation";
import cls from "./Locations.module.scss";
import { LocationsTable } from "./LocationsTable";

import { useDependenciesStore } from "../store";

export const Locations: React.FC = () => {
  const { t } = useTranslation();
  const data = useDependenciesStore((s) => s.locations);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Card>
        <h2>{t("dependencies.locations")}</h2>
        <p className={cls.description}>
          {t("dependencies.locationsDescription")}
        </p>
        {data && <LocationsTable locations={data} />}
        <Button onClick={() => setModalOpen(true)}>{t("common.add")}</Button>
      </Card>

      <Modal
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        title={t("dependencies.addLocation")}
      >
        <Card>
          <CreateLocation closeModal={() => setModalOpen(false)} />
        </Card>
      </Modal>
    </>
  );
};
