import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";
import { BoolResponse } from "@/utils/types";

import {
  GetHorsesAdvertsRes,
  GetFilteredHorsesAdvertsReq,
  HorseAdvert,
  GetAdvertsStatusesRes,
} from "./types";

export const getAllHorsesAdverts = async (): Promise<
  AxiosResponse<APIResponse<GetHorsesAdvertsRes>>
> => {
  return await axiosApi.get("/horses/getAll");
};

export const getHorseAdvert = async (
  _id: string
): Promise<AxiosResponse<APIResponse<HorseAdvert>>> => {
  return await axiosApi.get(`/horses/get/${_id}`);
};

export const pauseHorseAdvert = async (
  _id: string
): Promise<AxiosResponse<APIResponse<BoolResponse>>> => {
  return await axiosApi.patch(`/horses/pause/${_id}`);
};

export const onsellHorseAdvert = async (
  _id: string
): Promise<AxiosResponse<APIResponse<BoolResponse>>> => {
  return await axiosApi.patch(`/horses/onsell/${_id}`);
};

export const getFilteredHorsesAdverts = async (
  params: GetFilteredHorsesAdvertsReq
): Promise<AxiosResponse<APIResponse<GetHorsesAdvertsRes>>> => {
  return await axiosApi.get("/horses/find", { params });
};

export const getAdvertsStatuses = async (): Promise<
  AxiosResponse<APIResponse<GetAdvertsStatusesRes>>
> => {
  return await axiosApi.get("/horses/statuses");
};
