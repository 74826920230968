import cn from "classnames";
import { forwardRef } from "react";

import cls from "./Textarea.module.scss";

export type TextareaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type Props = {
  value?: TextareaProps["value"];
  onChange: TextareaProps["onChange"];
  placeholder?: TextareaProps["placeholder"];
  disabled?: TextareaProps["disabled"];
  type?: TextareaProps["type"];
  error?: boolean;
  fullwidth?: boolean;
  label?: string;
  readOnly?: boolean;
  suffix?: React.ReactNode;
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { label, suffix } = props;

  return (
    <div className={cls.root}>
      {label && (
        <div className={cls.label}>
          <p>{label}</p>
        </div>
      )}

      <div
        className={cn(cls.textarea, {
          [cls.error]: props.error,
          [cls.fullwidth]: props.fullwidth,
        })}
      >
        <textarea ref={ref} {...props} />
        {suffix && <div className={cls.suffix}>{suffix}</div>}
      </div>
    </div>
  );
});

Textarea.displayName = "Textarea";
