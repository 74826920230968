import { AccessorColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

export type TableData<T> = {
  data: T[];
  columns: {
    title: string;
    accessor: keyof T;
    width?: string;
  }[];
  groupBy?: keyof T;
};

export type GroupedData = {
  isGroupRow?: boolean;
};

export type MappedTableData<T> = {
  data: (T & GroupedData)[];
  columns: AccessorColumnDef<T>[];
};

type DataType = {
  onClick?: React.ReactNode;
} & Record<string, string | number | JSX.Element | boolean>;

function groupData<T extends DataType>(
  data: T[],
  groupByAccessor: keyof T | undefined
): T[] {
  const groupedData: Record<string, Array<T>> = {};
  if (typeof groupByAccessor === 'string' && data.length > 1) {
    let newMappedData: T[] = [];

    data.forEach((item) => {
      const groupByItem = item[groupByAccessor];
      if (typeof groupByItem !== 'string') {
        return;
      }
      if (!groupedData[groupByItem]) {
        groupedData[groupByItem] = [];
      }

      groupedData[groupByItem].push(item);
    });

    const groupField = Object.keys(data[0])[0];
    for (const groupKey in groupedData) {
      const groupRow: Record<string, string | boolean | undefined> =
        Object.fromEntries(
          Object.entries(data[0]).map(([key]) => {
            let value = undefined;
            if (key === groupField) {
              value = groupKey;
            }
            return [key, value];
          })
        );

      groupRow.isGroupRow = true;
      const oldItems = groupedData[groupKey];

      newMappedData = [
        ...newMappedData,
        ...[groupRow as unknown as T, ...oldItems]
      ];
    }

    return newMappedData;
  }

  return [];
}

export function useTableData<T extends DataType>(
  data: TableData<T>
): MappedTableData<T> {
  const groupByAccessor = data.groupBy;
  const rawData = data.data;
  const rawColumns = data.columns;

  // generate columns obj
  const columns = useMemo<AccessorColumnDef<T>[]>(() => {
    if (rawData.length === 0) {
      return [];
    }

    const mappedColumns: AccessorColumnDef<T>[] = rawColumns.map((column) => {
      const cellType = typeof rawData[0][column.accessor];

      return {
        header: column.title,
        accessorKey: column.accessor,
        cell: (info) =>
          cellType === 'object' ? info.renderValue() : info.getValue()
      };
    });

    if ('onClick' in rawData[0]) {
      mappedColumns.push({
        header: '',
        accessorKey: 'onClick',
        cell: (info) => info.renderValue()
      });
    }

    return mappedColumns;
  }, [rawData, rawColumns]);

  const mappedData = useMemo<T[]>(() => {
    const groupedData = groupData(rawData, groupByAccessor);
    return groupedData.length > 1 ? groupedData : rawData;
  }, [rawData, groupByAccessor]);

  return {
    data: mappedData,
    columns
  };
}
