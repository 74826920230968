import axiosLib, { AxiosInstance } from "axios";

import { useAuthStore } from "@/modules/Auth/store";
import { APP_AUTH_NAME, BASE_URL } from "@/utils/consts";
import { getCookie } from "@/utils/cookie";
import { Nullable } from "@/utils/types";

import { UNAUTHORIZED_STATUS } from "./consts";
import { makeRequestHeaders } from "./helpers";
import { APIResponse } from "./types";

const accessToken = getCookie(APP_AUTH_NAME);

export class HSApiClass {
  public axios: AxiosInstance = axiosLib;

  constructor(apiUrl: string) {
    this.init(apiUrl);
  }

  private init(apiUrl: string) {
    this.axios = axiosLib.create({
      baseURL: apiUrl,
      headers: makeRequestHeaders(accessToken),
    });

    this.axios.interceptors.response.use(
      (response) => {
        const result: APIResponse<unknown> = response.data;

        if (result.statusCode === UNAUTHORIZED_STATUS) {
          const authStore = useAuthStore.getState();
          authStore.resetAuth();
          window.location.href = "/signin";
        }

        return response;
      },
      (error) => {
        if (error.response) {
          return Promise.reject(error.response.data);
        } else if (error.request) {
          const requestError = error.request as XMLHttpRequest;
          return Promise.reject(requestError.statusText);
        }

        return Promise.reject(error);
      }
    );
  }

  public updateAccessToken(accessToken: Nullable<string>) {
    if (accessToken) {
      this.axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete this.axios.defaults.headers.Authorization;
    }
  }
}

export const HSApi = new HSApiClass(BASE_URL);
export const { axios: axiosApi } = HSApi;
