import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HorseAdvert } from "@/modules/SellList/types";

export const useAdvertName = (advert: HorseAdvert) => {
  const { t } = useTranslation();

  const advertTitle = useMemo(() => {
    const titleParts: string[] = [];

    titleParts.push(`# ${advert.vendorCode}`);
    advert.gender?.name && titleParts.push(advert.gender?.name);
    advert.birthYear &&
      titleParts.push(t("advert.birthYear", { birthYear: advert.birthYear }));
    advert.height &&
      titleParts.push(t("advert.height", { height: advert.height }));

    return titleParts.join(t("common.commaSeparator"));
  }, [
    advert.birthYear,
    advert.gender?.name,
    advert.height,
    advert.vendorCode,
    t,
  ]);

  return advertTitle;
};
