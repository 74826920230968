import { create } from "zustand";

import { useToast } from "@/hooks/useToast";
import { Nullable } from "@/utils/types";

import { getProfileData } from "./api";
import { UserType } from "./types";

interface UserStore {
  user: Nullable<UserType>;
  fetchUser: (customFetch: () => Promise<Nullable<UserType>>) => void;
}

const useCustomFetch = (): (() => Promise<Nullable<UserType>>) => {
  const { showError } = useToast();

  return async () => {
    try {
      const result = await getProfileData();
      return result.data.data;
    } catch (error) {
      showError(error);
    }

    return null;
  };
};

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  fetchUser: async (customFetch) => {
    const response = await customFetch();
    set({ user: response });
  },
}));

export const useUserFetch = () => {
  const customFetch = useCustomFetch();
  return () => useUserStore.getState().fetchUser(customFetch);
};
