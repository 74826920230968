/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Nullable, OptionI } from "@/utils/types";

import { ArrowIcon, CancelIcon } from "./icons";
import cls from "./Select.module.scss";

import { Dropdown } from "../Dropdown/Dropdown";
import { Input, InputProps } from "../Input/Input";

type Props = {
  options: OptionI[];
  value?: OptionI["id"];
  onChange: InputProps["onChange"];
  label?: string;
  placeholder?: InputProps["placeholder"];
};

export const Select: React.FC<Props> = ({
  options,
  value,
  onChange,
  label,
  placeholder,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [option, setOption] = useState<Nullable<OptionI>>(null);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = useMemo(
    () =>
      searchQuery
        ? options.filter((option) =>
            option.text.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : options,
    [options, searchQuery]
  );

  const setValue = (newValue: string) => {
    if (!inputRef.current) return;

    const input = inputRef.current;
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    )?.set;
    nativeInputValueSetter && nativeInputValueSetter.call(input, newValue);
    const event = new Event("input", { bubbles: true });
    input.dispatchEvent(event);
  };

  const createSynteticEvent = (option: OptionI) => {
    setValue(option.id);
  };

  const onSelect = (option: OptionI) => {
    setOption(option);
    setDropdownOpen(false);
  };

  const restoreOptionFromValue = () => {
    if (value && !option) {
      const findedOption = options.find((o) => o.id === value);
      findedOption && setOption(findedOption);
    }
  };

  const onClear = () => {
    setValue("");
  };

  useEffect(() => {
    if (option) createSynteticEvent(option);
  }, [option]);

  useEffect(() => {
    restoreOptionFromValue();
  }, [value]);

  useEffect(() => {
    if (!value && option) setOption(null);
  }, [value]);

  return (
    <div className={cls.root}>
      <input
        ref={inputRef}
        className={cls.hidden}
        value={value}
        onChange={onChange}
      />

      <button
        className={cls.select_btn}
        type="button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <Input
          value={option?.text || ""}
          onChange={() => null}
          readOnly
          label={label}
          placeholder={placeholder}
          suffix={<ArrowIcon />}
        />
      </button>

      {value && (
        <button className={cls.cancel_btn} onClick={onClear}>
          <CancelIcon />
        </button>
      )}

      <Dropdown
        isOpen={dropdownOpen}
        close={() => setDropdownOpen(false)}
        options={filteredOptions}
        onSelect={onSelect}
      >
        <div className={cls.search}>
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            placeholder={t("common.search")}
          />
        </div>
      </Dropdown>
    </div>
  );
};
