import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import {
  CreateLocationReq,
  CreateLocationRes,
  DeleteLocationReq,
  DeleteLocationRes,
  UpdateLocationReq,
  UpdateLocationRes,
  GetAllLocationsRes,
} from "./types";

export const getAllLocations = async (): Promise<
  AxiosResponse<APIResponse<GetAllLocationsRes>>
> => {
  return await axiosApi.get("/locations/all");
};

export const createLocation = async (
  req: CreateLocationReq
): Promise<AxiosResponse<APIResponse<CreateLocationRes>>> => {
  return await axiosApi.post("/locations/add", req);
};

export const updateLocation = async (
  req: UpdateLocationReq
): Promise<AxiosResponse<APIResponse<UpdateLocationRes>>> => {
  return await axiosApi.patch("/locations/update", req);
};

export const deleteLocation = async (
  req: DeleteLocationReq
): Promise<AxiosResponse<APIResponse<DeleteLocationRes>>> => {
  return await axiosApi.delete("/locations/delete", {
    data: {
      ...req,
    },
  });
};
