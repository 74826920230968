import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import { UserType } from "./types";

export const getProfileData = async (): Promise<
  AxiosResponse<APIResponse<UserType>>
> => {
  return await axiosApi.get("/users/profile");
};
