import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { useToast } from "@/hooks/useToast";

import { deleteLocation } from "./api";
import cls from "./Locations.module.scss";
import { LocationType } from "./types";

import { useDependenciesStore } from "../store";

type Props = {
  location: LocationType;
  cancel: () => void;
};

export const DeleteLocation: React.FC<Props> = ({ location, cancel }) => {
  const { t } = useTranslation();
  const { showError } = useToast();

  const [loading, setLoading] = useState(false);

  const fetchLocations = useDependenciesStore((s) => s.fetchLocations);

  const onLocationDelete = async () => {
    setLoading(true);

    try {
      const req = {
        id: location._id,
      };
      await deleteLocation(req);
      fetchLocations();
      cancel();
    } catch (err) {
      const error = err as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        showError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.delete}>
      <p>{t("dependencies.deleteLocation", { name: location.name })}</p>
      <div className={cls.delete_actions}>
        <Button onClick={cancel} disabled={loading}>
          {t("common.cancel")}
        </Button>
        <Button onClick={onLocationDelete} variant="danger" disabled={loading}>
          {t("common.confirm")}
        </Button>
      </div>
    </div>
  );
};
