export const SELECT_STYLE_OVERRIDES = {
  ControlContainer: {
    style: {
      backgroundColor: "white",
      borderTopLeftRadius: "32px",
      borderTopRightRadius: "32px",
      borderBottomRightRadius: "32px",
      borderBottomLeftRadius: "32px",
      borderTopWidth: "1.5px",
      borderRightWidth: "1.5px",
      borderBottomWidth: "1.5px",
      borderLeftWidth: "1.5px",
      borderTopColor: "var(--thm-grey-1)",
      borderRightColor: "var(--thm-grey-1)",
      borderBottomColor: "var(--thm-grey-1)",
      borderLeftColor: "var(--thm-grey-1)",
      padding: "0 0 0 0",
      height: "44px",
      alignItems: "center",
    },
  },
  Placeholder: {
    style: {
      color: "var(--thm-silver-grey-1)",
    },
  },
  Input: {
    style: {
      fontSize: "15px",
    },
  },
  ValueContainer: {
    style: {
      fontSize: "15px",
      lineHeight: "17px",

      paddingRight: 0,
      paddingLeft: "14px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
};
