/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageTitle } from "@/components/PageTitle/PageTitle";
import { PaginationPages } from "@/components/PaginationPages/PaginationPages";
import { Spinner } from "@/components/Spinner/Spinner";
import { useFetchWithParams } from "@/hooks/useFetch";
import { usePagination } from "@/hooks/usePagination";
import { DEFAULT_TAKE_COUNT } from "@/utils/consts";

import { getPaginatedSessions } from "./api";
import { SessionCard } from "./SessionCard/SessionCard";
import cls from "./SessionsList.module.scss";
import { Session } from "./types";

export const SessionsList: React.FC = () => {
  const { t } = useTranslation();
  const [takeCount, setTakeCount] = useState(DEFAULT_TAKE_COUNT);
  const [sessionsList, setSessionsList] = useState<Session[]>([]);

  const {
    page,
    take,
    onNextPage,
    onPrevPage,
    handleMetaData,
    hasNextPage,
    hasPrevPage,
    itemsCount,
  } = usePagination(takeCount);

  const params = useMemo(() => ({ page, take }), [page, take]);
  const { data, loading } = useFetchWithParams(getPaginatedSessions, params);

  const getPagesCount = () => {
    if (!data.data) return;

    handleMetaData(data.meta);
    setSessionsList(data.data);
  };

  useEffect(() => {
    getPagesCount();
  }, [data]);

  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.sessions")} />
      {loading && (
        <div className={cls.placeholder}>
          <Spinner />
        </div>
      )}

      {!loading && (
        <div className={cls.sessions}>
          <h1>{t("search.sessions")}</h1>
          <ul className={cls.list}>
            {sessionsList.map((session) => (
              <li key={session._id}>
                <SessionCard session={session} />
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={cls.pagination}>
        <PaginationPages
          currentPage={page}
          itemsCount={itemsCount}
          takeCount={take}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          setTakeCount={setTakeCount}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </div>
    </div>
  );
};
