import { useMemo } from "react";

import { useDependenciesStore } from "../store";

export const useSpecializations = () => {
  const specializations = useDependenciesStore((s) => s.specializations);
  const specializationsOptions = useMemo(
    () =>
      specializations
        ? specializations.map((specialization) => ({
            id: specialization._id,
            text: specialization.name,
          }))
        : [],
    [specializations]
  );

  return specializationsOptions;
};
