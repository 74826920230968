import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components/Card/Card";
import { Modal } from "@/components/Modal/Modal";

import cls from "./Currencies.module.scss";
import { DeleteCurrency } from "./DeleteCurrency";
import { DeleteIcon, EditIcon } from "./icons";
import { CurrencyType } from "./types";
import { UpdateCurrency } from "./UpdateCurrency";

type Props = {
  currency: CurrencyType;
};

export const CurrencyActions: React.FC<Props> = ({ currency }) => {
  const { t } = useTranslation();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <div className={cls.actions}>
        <button type="button" onClick={() => setUpdateModalOpen(true)}>
          <EditIcon />
        </button>
        <button type="button" onClick={() => setDeleteModalOpen(true)}>
          <DeleteIcon />
        </button>
      </div>

      <Modal
        isOpen={updateModalOpen}
        close={() => setUpdateModalOpen(false)}
        title={t("dependencies.updateCurrency")}
      >
        <Card>
          <UpdateCurrency currency={currency} />
        </Card>
      </Modal>

      <Modal isOpen={deleteModalOpen} close={() => setDeleteModalOpen(false)}>
        <Card>
          <DeleteCurrency
            currency={currency}
            cancel={() => setDeleteModalOpen(false)}
          />
        </Card>
      </Modal>
    </>
  );
};
