import { create } from "zustand";

import { onlyNumbers } from "@/utils/format";
import { CommonObj, Nullable } from "@/utils/types";

import { HorseAdvert } from "../SellList/types";

interface SearchReq {
  vendorCode?: Nullable<HorseAdvert["vendorCode"]>;
  priceFrom?: Nullable<HorseAdvert["price"]>;
  priceTo?: Nullable<HorseAdvert["price"]>;
  gender?: Nullable<CommonObj["_id"]>;
  birthYearFrom?: Nullable<HorseAdvert["birthYear"]>;
  birthYearTo?: Nullable<HorseAdvert["birthYear"]>;
  location?: Nullable<CommonObj["_id"]>;
  status?: Nullable<HorseAdvert["status"]>;
}

interface SearchStore {
  vendorCode: SearchReq["vendorCode"];
  setVendorCode: (value: string) => void;
  priceFrom: SearchReq["priceFrom"];
  setPriceFrom: (value: string) => void;
  priceTo: SearchReq["priceTo"];
  setPriceTo: (value: string) => void;
  gender: SearchReq["gender"];
  setGender: (value: SearchReq["gender"]) => void;
  birthYearFrom: SearchReq["birthYearFrom"];
  setBirthYearFrom: (value: string) => void;
  birthYearTo: SearchReq["birthYearTo"];
  setBirthYearTo: (value: string) => void;
  location: SearchReq["location"];
  setLocation: (value: SearchReq["location"]) => void;
  status: SearchReq["status"];
  setStatus: (value: SearchReq["status"]) => void;
}

export const useSearchStore = create<SearchStore>((set) => ({
  vendorCode: null,
  setVendorCode: (vendorCode) => set({ vendorCode: onlyNumbers(vendorCode) }),
  priceFrom: null,
  setPriceFrom: (priceFrom) =>
    set({ priceFrom: Number(onlyNumbers(priceFrom)) }),
  priceTo: null,
  setPriceTo: (priceTo) => set({ priceTo: Number(onlyNumbers(priceTo)) }),
  gender: null,
  setGender: (gender) => set({ gender }),
  birthYearFrom: null,
  setBirthYearFrom: (birthYearFrom) =>
    set({ birthYearFrom: Number(onlyNumbers(birthYearFrom)) }),
  birthYearTo: null,
  setBirthYearTo: (birthYearTo) =>
    set({ birthYearTo: Number(onlyNumbers(birthYearTo)) }),
  location: null,
  setLocation: (location) => set({ location }),
  status: null,
  setStatus: (status) => set({ status }),
}));
