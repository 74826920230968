import cn from "classnames";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import cls from "./FileUploader.module.scss";
import { CameraIcon } from "./icons";

type Props = {
  onFileSelected: (file: File) => void;
  disabled?: boolean;
};

export const FileUploader: React.FC<Props> = ({ onFileSelected, disabled }) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        onFileSelected(file);
      });
    },
    [onFileSelected]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
  });

  return (
    <div
      className={cn(cls.root, { [cls.root_active]: isDragActive })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <CameraIcon />

      {disabled && <p>{t("common.imageLoading")}</p>}

      {!disabled && (
        <>
          {isDragActive ? (
            <p>{t("common.onDragDrop")}</p>
          ) : (
            <p>{t("common.dragDrop")}</p>
          )}
        </>
      )}
    </div>
  );
};
