import cn from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MiniAdvert } from "@/modules/Advert/MiniAdvert/MiniAdvert";

import cls from "./SessionAdverts.module.scss";

import { Session } from "../types";

type Props = {
  adverts: Session["adverts"];
};

export const SessionAdverts: React.FC<Props> = ({ adverts }) => {
  const { t } = useTranslation();

  const likedAdverts = useMemo(
    () => adverts.filter((advert) => advert.like),
    [adverts]
  );

  const dislikedAdverts = useMemo(
    () => adverts.filter((advert) => advert.dislike),
    [adverts]
  );

  return (
    <div className={cls.root}>
      <div className={cls.rated}>
        <h2>{t("sessions.likedAdverts")}</h2>
        {likedAdverts.length !== 0 && (
          <ul className={cls.list}>
            {likedAdverts.map((advert) => (
              <li key={advert._id}>
                <MiniAdvert advert={advert} />
              </li>
            ))}
          </ul>
        )}

        {likedAdverts.length === 0 && (
          <p className={cls.empty}>{t("sessions.emptyAdverts")}</p>
        )}
      </div>

      <div className={cn(cls.rated, cls.dislikes)}>
        <h2>{t("sessions.dislikedAdverts")}</h2>
        {dislikedAdverts.length !== 0 && (
          <ul className={cls.list}>
            {dislikedAdverts.map((advert) => (
              <li key={advert._id}>
                <MiniAdvert advert={advert} />
              </li>
            ))}
          </ul>
        )}

        {dislikedAdverts.length === 0 && (
          <p className={cls.empty}>{t("sessions.emptyAdverts")}</p>
        )}
      </div>
    </div>
  );
};
