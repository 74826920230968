import { create } from "zustand";

import { HSApi } from "@/api/api";
import { APP_AUTH_NAME } from "@/utils/consts";
import { getCookie, setCookie } from "@/utils/cookie";
import { Nullable } from "@/utils/types";

export enum AuthState {
  guest = "guest",
  unregistered = "unregistered",
  logged = "logged",
}

interface AuthStore {
  authState: AuthState;
  setAuthState: (value: AuthState) => void;
  accessToken: Nullable<string>;
  setAccessToken: (value: Nullable<string>) => void;
  getAccessTokenFromCookie: () => void;
  setAccessTokenToCookie: () => void;
  resetAuth: () => void;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  authState: AuthState.guest,
  setAuthState: (value) => set({ authState: value }),
  accessToken: null,
  setAccessToken: (value) => {
    set({ accessToken: value });
    get().setAccessTokenToCookie();
  },
  getAccessTokenFromCookie: () => {
    const accessToken = getCookie(APP_AUTH_NAME) || null;
    get().setAuthState(accessToken ? AuthState.logged : AuthState.unregistered);
    HSApi.updateAccessToken(accessToken);
    set({ accessToken: accessToken });
  },
  setAccessTokenToCookie: () => {
    const accessToken = get().accessToken;
    get().setAuthState(accessToken ? AuthState.logged : AuthState.unregistered);
    HSApi.updateAccessToken(accessToken);
    setCookie(APP_AUTH_NAME, accessToken || "");
  },
  resetAuth: () => {
    get().setAccessToken(null);
    HSApi.updateAccessToken(null);
  },
}));
