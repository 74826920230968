/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Input } from "@/components/Input/Input";
import { PageTitle } from "@/components/PageTitle/PageTitle";
import { validateEmail } from "@/utils/validate";

import cls from "./Signin.module.scss";

import { signin } from "../api";
import { AuthState, useAuthStore } from "../store";
import { SigninRes } from "../types";

export const Signin: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const authState = useAuthStore((s) => s.authState);
  const setAccessToken = useAuthStore((s) => s.setAccessToken);

  const isValid = validateEmail(email) && !!password && !isError;

  const onLogin = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      const req = {
        email: email,
        password: password,
      };

      const res = await signin(req);
      if (res.data.data.accessToken) {
        setAccessToken(res.data.data.accessToken);
        navigate("/");
      }
    } catch (err) {
      const error = err as Error | AxiosError<SigninRes>;
      if (!axios.isAxiosError(error)) {
        console.error("Login error: ", error);
      }
      setError(true);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState === AuthState.logged) navigate("/");
  }, [authState]);

  useEffect(() => {
    if (!isError && !errorMessage) return;

    setError(false);
    setErrorMessage("");
  }, [email, password]);

  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.signIn")} />
      <Card>
        <div className={cls.inner}>
          <h1>{t("auth.signin.title")}</h1>
          {errorMessage && <p className={cls.error}>{errorMessage}</p>}
          <div className={cls.inputs}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder={t("common.email")}
              error={isError}
              fullwidth
            />
            <Input
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              placeholder={t("common.password")}
              type="password"
              error={isError}
              onKeyDown={(e) => {
                if (e.key === "Enter") onLogin();
              }}
              fullwidth
            />
          </div>
          <Button disabled={!isValid || isLoading} onClick={onLogin}>
            {t("common.continue")}
          </Button>
        </div>
      </Card>
    </div>
  );
};
