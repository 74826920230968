import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";
import { PaginationReq } from "@/utils/types";

import { GetPaginatedClientsRes } from "./types";

export const getPaginatedClients = async (
  params?: PaginationReq
): Promise<AxiosResponse<APIResponse<GetPaginatedClientsRes>>> => {
  return await axiosApi.get("/clients/list", { params });
};
