export function CancelIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7364 3.2636C13.0879 3.61507 13.0879 4.18492 12.7364 4.53639L9.27299 7.99999L12.7364 11.4636C13.0586 11.7858 13.0854 12.2915 12.8169 12.6442L12.7364 12.7364C12.3849 13.0879 11.8151 13.0879 11.4636 12.7364L7.99999 9.27299L4.53639 12.7364C4.18492 13.0879 3.61507 13.0879 3.2636 12.7364C2.91212 12.3849 2.91212 11.8151 3.2636 11.4636L6.72699 7.99999L3.2636 4.53639C2.94141 4.21421 2.91457 3.70853 3.18305 3.35576L3.2636 3.2636C3.61507 2.91212 4.18492 2.91212 4.53639 3.2636L7.99999 6.72699L11.4636 3.2636C11.8151 2.91212 12.3849 2.91212 12.7364 3.2636Z"
        fill="currentColor"
      />
    </svg>
  );
}
