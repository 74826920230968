export type Nullable<T> = T | null;

export interface PaginationReq {
  page?: number;
  take?: number;
}

export interface CommonObj {
  _id: string;
  name: string;
}

export interface PhotoType {
  photo_url: string;
  thumbnail_url: Nullable<string>;
}

export interface ApiError {
  code: number;
  message: string;
}

export interface OptionI {
  id: string;
  text: string;
  value?: string;
  extra?: string;
}

export enum AdvertStatusType {
  onsell = "onsell",
  draft = "draft",
  pause = "pause",
}

export interface BoolResponse {
  result: boolean;
}

export interface PaginationResponse<T = unknown> {
  data: T;
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
}
