/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { Input } from "@/components/Input/Input";
import { PageTitle } from "@/components/PageTitle/PageTitle";
import { StatelessMultiSelect } from "@/components/StatelessSelect/StatelessMultiSelect";
import { StatelessSelect } from "@/components/StatelessSelect/StatelessSelect";
import { Textarea } from "@/components/Textarea/Textarea";
import { useToast } from "@/hooks/useToast";
import { getImageSrc } from "@/utils/consts";
import { removeEmptyParams } from "@/utils/format";
import { AdvertStatusType, Nullable, OptionI } from "@/utils/types";

import { AdvertImages } from "./AdvertImages/AdvertImages";
import { addHorseDraft, addHorsePublic, updateHorse } from "./api";
import cls from "./CreateAdvert.module.scss";
import { AddHorseReq } from "./types";

import { HorseAdvert } from "../SellList/types";
import { useGenders } from "../Settings/Dependencies/Genders/hooks";
import { useLocations } from "../Settings/Dependencies/Locations/hooks";
import { useSpecializations } from "../Settings/Dependencies/Specializations/hooks";
import { useDependenciesStore } from "../Settings/Dependencies/store";

const formExtraKeys = ["_id", "__v", "photos", "status"];

const AddHorseSchema = yup.object({
  vendorCode: yup.number().positive().integer(),
  price: yup.number().positive().integer().nullable(),
  breed: yup.string().nullable(),
  birthYear: yup.number().positive().integer().nullable(),
  height: yup.number().positive().integer().nullable(),
  description: yup.string().nullable(),
  notes: yup.string().nullable(),
});

type AddHorseForm = Omit<AddHorseReq, "photos">;

type Props = {
  advertToEdit?: HorseAdvert;
};

export const CreateAdvert: React.FC<Props> = ({ advertToEdit }) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<AddHorseForm>({
    resolver: yupResolver(AddHorseSchema),
    defaultValues: {
      price: null,
      breed: null,
      birthYear: null,
      height: null,
      description: null,
      notes: null,
    },
  });

  const [savedAdvertId, setSavedAdvertId] = useState<Nullable<string>>(null);
  const [imageSrc, setImageSrc] = useState("");
  const [imageName, setImageName] = useState("");

  const [isLoading, setLoading] = useState(false);

  const currencies = useDependenciesStore((s) => s.currencies);
  const currenciesOptions = useMemo<OptionI[]>(
    () =>
      currencies
        ? currencies.map((currency) => ({
            id: currency._id,
            text: `${currency.symbol} - ${currency.name}`,
          }))
        : [],
    [currencies]
  );

  const gendersOptions = useGenders();
  const locationsOptions = useLocations();
  const specializations = useSpecializations();

  const onImageUploaded = (imagePath: string) => {
    if (!imagePath) {
      setImageSrc("");
      return;
    }

    setImageName(imagePath);
    setImageSrc(getImageSrc(imagePath));
  };

  const onImageDelete = () => {
    setImageName("");
    setImageSrc("");
  };

  const checkFormValid = () => {
    const invalidFields = Object.keys(errors).map((key) => t(`common.${key}`));
    if (invalidFields.length > 0)
      showError(
        t("create.checkRequired", { fields: invalidFields.join(", ") })
      );
  };

  useEffect(() => {
    checkFormValid();
  }, [errors]);

  const resetForm = () => {
    reset();
    setSavedAdvertId(null);
    onImageDelete();
  };

  const onUpdateHorse = async (
    status: AdvertStatusType = AdvertStatusType.draft,
    data: AddHorseForm
  ) => {
    if (!savedAdvertId) return;
    setLoading(true);

    try {
      const req = {
        ...removeEmptyParams(data),
        _id: savedAdvertId,
        status,
        photos: [imageName],
      };
      await updateHorse(req);
      showSuccess(t("create.advertUpdated"));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onCreateHorse = async (
    status: AdvertStatusType = AdvertStatusType.draft,
    data: AddHorseForm
  ) => {
    setLoading(true);
    const createPromise =
      status === AdvertStatusType.draft ? addHorseDraft : addHorsePublic;
    try {
      const req = { ...removeEmptyParams(data), status, photos: [imageName] };
      const saveResult = await createPromise(req);
      const savedAdvertId = saveResult.data.data.id;
      setSavedAdvertId(savedAdvertId);
      showSuccess(t("create.advertSaved"));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitPublic: SubmitHandler<AddHorseForm> = async (data) => {
    const promise = !savedAdvertId ? onCreateHorse : onUpdateHorse;
    promise(AdvertStatusType.onsell, data);
  };
  const onSubmitDraft: SubmitHandler<AddHorseForm> = async (data) => {
    const promise = !savedAdvertId ? onCreateHorse : onUpdateHorse;
    promise(AdvertStatusType.draft, data);
  };

  const enableEditMode = () => {
    if (!advertToEdit) return;

    (Object.keys(advertToEdit) as Array<keyof typeof advertToEdit>)
      .filter((key) => !formExtraKeys.includes(key))
      .forEach((key) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setValue(key as keyof AddHorseForm, advertToEdit[key] as any)
      );

    const imgArr = advertToEdit.photos;
    if (imgArr && typeof imgArr[0] === "string") {
      setImageName(imgArr[0]);
      setImageSrc(getImageSrc(imgArr[0]));
    }

    setSavedAdvertId(advertToEdit._id);
  };

  useEffect(() => {
    if (advertToEdit) enableEditMode();
  }, []);

  return (
    <>
      <PageTitle title={t("nav.createAdvert")} />
      <Card>
        <form onSubmit={handleSubmit(onSubmitDraft)}>
          <h1>{!advertToEdit ? t("create.title") : t("create.editTitle")}</h1>
          <div className={cls.container}>
            <div className={cls.head}>
              <div className={cls.pics}>
                <AdvertImages
                  onImageUploaded={onImageUploaded}
                  onImageDelete={onImageDelete}
                  imageSrc={imageSrc}
                />
              </div>
              <div className={cls.info}>
                <Input
                  label={t("common.vendorCode")}
                  placeholder={t("create.vendorCodePlaceholder")}
                  {...register("vendorCode")}
                />
                <div className={cls.input_group}>
                  <Input
                    label={t("common.price")}
                    placeholder={t("create.pricePlaceholder")}
                    {...register("price")}
                  />

                  <Controller
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <StatelessSelect
                        options={currenciesOptions}
                        value={value}
                        onChange={onChange}
                        label={t("common.currency")}
                        placeholder={t("create.currencyPlaceholder")}
                      />
                    )}
                    name="currency"
                  />
                </div>

                <Controller
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <StatelessSelect
                      options={gendersOptions}
                      value={value}
                      onChange={onChange}
                      label={t("common.gender")}
                      placeholder={t("create.genderPlaceholder")}
                    />
                  )}
                  name="gender"
                />

                <Input
                  label={t("common.breed")}
                  placeholder={t("create.breedPlaceholder")}
                  {...register("breed")}
                />

                <Controller
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <StatelessSelect
                      options={locationsOptions}
                      label={t("common.location")}
                      placeholder={t("create.locationPlaceholder")}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  name="location"
                />
              </div>
            </div>
            <div className={cls.main_info}>
              <Controller
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <StatelessMultiSelect
                    options={specializations}
                    label={t("common.specialization")}
                    placeholder={t("create.specializationPlaceholder")}
                    onChange={onChange}
                    value={value || []}
                  />
                )}
                name="specializations"
              />
              <div className={cls.group}>
                <Input
                  label={t("common.birthYear")}
                  placeholder={t("create.birthYearPlaceholder")}
                  {...register("birthYear")}
                />
                <Input
                  label={t("common.height")}
                  placeholder={t("create.heightPlaceholder")}
                  {...register("height")}
                />
                <Input
                  label={t("create.photosLink")}
                  {...register("photosLink")}
                />
              </div>

              <Textarea
                label={t("create.outerDescription")}
                placeholder={t("create.outerDescriptionPlaceholder")}
                {...register("description")}
              />

              <Textarea
                label={t("create.note")}
                placeholder={t("create.notePlaceholder")}
                {...register("notes")}
              />
            </div>
            <div className={cls.actions}>
              {savedAdvertId && !advertToEdit && (
                <div className={cls.reset}>
                  <Button
                    onClick={resetForm}
                    variant="danger"
                    disabled={isLoading}
                  >
                    {t("create.resetForm")}
                  </Button>
                </div>
              )}
              <Button
                onClick={() => handleSubmit(onSubmitDraft)()}
                variant="secondary"
                disabled={isLoading}
              >
                {t("create.saveDraft")}
              </Button>
              <Button
                onClick={() => handleSubmit(onSubmitPublic)()}
                disabled={isLoading}
              >
                {t("create.saveAndPublish")}
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
};
