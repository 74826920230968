import { useTranslation } from "react-i18next";

import { useSearchStore } from "@/modules/Search/store";
import { STATUS_COLORS } from "@/utils/consts";
import { AdvertStatusType } from "@/utils/types";

import { CancelIcon } from "./icons";
import cls from "./StatusFilter.module.scss";

import { AdvertStatus } from "../types";

type Props = {
  statuses: AdvertStatus[];
};

export const StatusFilter: React.FC<Props> = ({ statuses }) => {
  const { t } = useTranslation();

  const status = useSearchStore((s) => s.status);
  const setStatus = useSearchStore((s) => s.setStatus);

  const onStatusClick = (selectedStatus: AdvertStatusType) => {
    if (status === selectedStatus) {
      setStatus(null);
      return;
    }

    setStatus(selectedStatus);
  };

  return (
    <ul className={cls.root}>
      {statuses.map((s) => {
        const statusBackgroundColor = STATUS_COLORS[s._id];
        const isStatusActive = status && status === s._id;
        const showStatus = !status || isStatusActive;

        if (!showStatus) return;

        return (
          <li
            key={s._id}
            style={{
              backgroundColor: statusBackgroundColor.bg,
              color: statusBackgroundColor.text,
            }}
          >
            <button onClick={() => onStatusClick(s._id)}>
              <p>
                {t(`common.status.${s._id}`)} - {s.count}
              </p>
              {isStatusActive && <CancelIcon />}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
