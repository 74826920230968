import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { useToast } from "@/hooks/useToast";

import { deleteSpecialization } from "./api";
import cls from "./Specializations.module.scss";
import { SpecializationType } from "./types";

import { useDependenciesStore } from "../store";

type Props = {
  specialization: SpecializationType;
  cancel: () => void;
};

export const DeleteSpecialization: React.FC<Props> = ({
  specialization,
  cancel,
}) => {
  const { t } = useTranslation();
  const { showError } = useToast();

  const [loading, setLoading] = useState(false);

  const fetchSpecializations = useDependenciesStore(
    (s) => s.fetchSpecializations
  );

  const onSpecializationDelete = async () => {
    setLoading(true);

    try {
      const req = {
        id: specialization._id,
      };
      await deleteSpecialization(req);
      fetchSpecializations();
      cancel();
    } catch (err) {
      const error = err as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        showError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.delete}>
      <p>
        {t("dependencies.deleteSpecialization", { name: specialization.name })}
      </p>
      <div className={cls.delete_actions}>
        <Button onClick={cancel} disabled={loading}>
          {t("common.cancel")}
        </Button>
        <Button
          onClick={onSpecializationDelete}
          variant="danger"
          disabled={loading}
        >
          {t("common.confirm")}
        </Button>
      </div>
    </div>
  );
};
