import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { PageTitle } from "@/components/PageTitle/PageTitle";
import { useFetchWithParams } from "@/hooks/useFetch";

import { CreateAdvert } from "../CreateAdvert/CreateAdvert";
import { getHorseAdvert } from "../SellList/api";

export const EditAdvert: React.FC = () => {
  const { t } = useTranslation();
  const { advertId } = useParams();

  const { data, loading } = useFetchWithParams(
    getHorseAdvert,
    advertId || null
  );

  return (
    <>
      <PageTitle title={t("nav.editAdvert")} />
      {data && !loading && <CreateAdvert advertToEdit={data} />}

      {loading && <p>Loading...</p>}
    </>
  );
};
