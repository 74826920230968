import { InputProps } from "baseui/input";
import { OnChangeParams, Select, Value } from "baseui/select";
import { useEffect, useState } from "react";

import { OptionI } from "@/utils/types";

import { SELECT_STYLE_OVERRIDES } from "./consts";
import cls from "./StatelessSelect.module.scss";

type Props = {
  options: OptionI[];
  onChange: (value?: string[]) => void;
  value?: string[];
  label?: string;
  placeholder?: InputProps["placeholder"];
};

export const StatelessMultiSelect: React.FC<Props> = ({
  options,
  onChange,
  placeholder,
  value,
  label,
}) => {
  const getInitialValue = () => {
    if (!value) return [];

    const existingIds = new Set(options.map((obj) => obj.id));
    return options.filter(
      (obj) => existingIds.has(obj.id) && value.includes(obj.id)
    );
  };

  const [selectedValue, setSelectedValue] = useState<Value>([]);

  const onValueChange = (params: OnChangeParams) => {
    const { value } = params;
    setSelectedValue(value);
    onChange(value.filter((val) => val.id).map((val) => val.id!.toString()));
  };

  useEffect(() => {
    if (!!value?.length && !selectedValue.length)
      setSelectedValue(getInitialValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedValue]);

  useEffect(() => {
    if (value?.length === 0 && selectedValue.length !== 0) setSelectedValue([]);
  }, [value, selectedValue]);

  return (
    <div className={cls.root}>
      {label && <p className={cls.label}>{label}</p>}

      <Select
        options={options}
        labelKey="text"
        valueKey="id"
        placeholder={placeholder}
        maxDropdownHeight="300px"
        onChange={onValueChange}
        value={selectedValue}
        multi
        overrides={SELECT_STYLE_OVERRIDES}
      />
    </div>
  );
};
