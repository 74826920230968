import { AdvertStatusType } from "./types";

// Env
const isProdApi = process.env.REACT_APP_SERVER === "prod";
export const isBrowser = typeof window !== "undefined";

// Urls
export const HOSTNAME = isProdApi ? "horsemarket.site" : "localhost";
export const APP_URL = isProdApi
  ? "https://horsemarket.site/api"
  : "http://192.168.1.87:8000";
export const BASE_URL = isProdApi ? `${APP_URL}` : `${APP_URL}`;
export const GET_IMAGE_URL = "/filebox/image/";
export const getImageSrc = (imagePath: string) =>
  `${APP_URL}${GET_IMAGE_URL}${imagePath}`;

// Other
export const APP_AUTH_NAME = "hs-app-auth-token";
export const MODAL_ROOT_ID = "modal-root";

export const BREAKPOINT = 770;
export const MOB_BREAKPOINT = 500;
export const HOURS_IN_DAY = 24;
export const SECONDS_IN_DAY = 86400;

export const timeZone = isBrowser
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : "";

export const STATUS_COLORS: Record<
  AdvertStatusType,
  { bg: string; text: string }
> = {
  [AdvertStatusType.draft]: {
    bg: "var(--thm-grey-1)",
    text: "var(--thm-grey-4)",
  },
  [AdvertStatusType.onsell]: {
    bg: "var(--clr-green-15)",
    text: "var(--clr-green-dark)",
  },
  [AdvertStatusType.pause]: {
    bg: "var(--clr-blue-15)",
    text: "var(--clr-blue)",
  },
};

export const DEFAULT_TAKE_COUNT = 5;
export const DEFAULT_PAGINATION_TAKE_OPTIONS = [
  { id: "5", text: "5" },
  { id: "25", text: "25" },
  { id: "50", text: "50" },
  { id: "100", text: "100" },
];
