import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Card } from "@/components/Card/Card";
import { PageTitle } from "@/components/PageTitle/PageTitle";
import { useFetchWithParams } from "@/hooks/useFetch";

import cls from "./Advert.module.scss";

import { getHorseAdvert } from "../SellList/api";

export const Advert: React.FC = () => {
  const { t } = useTranslation();

  const { advertId } = useParams();

  const { data, loading } = useFetchWithParams(
    getHorseAdvert,
    advertId || null
  );

  return (
    <div className={cls.root}>
      <PageTitle title={t("nav.advert")} />
      {!data && !loading && (
        <>
          <h1>Объявление не найдено</h1>
        </>
      )}

      {data && (
        <>
          <h1>Просмотр объявления</h1>
          <Card>Test</Card>
        </>
      )}
    </div>
  );
};
