import { SECONDS_IN_DAY } from "@/utils/consts";

type CookieOptions = {
  "max-age"?: number;
  expires?: Date | number | string;
  path?: string;
  domain?: string;
  secure?: boolean;
};

type CookieOptionsKey = keyof CookieOptions;

const ENV = process.env.NODE_ENV;

function getCookieDomain(): string {
  if (ENV === "development") {
    return "";
  }

  const { hostname } = window.location;

  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (ipRegex.test(hostname)) {
    return hostname;
  }

  const split = hostname.split(".");
  const len = split.length;

  if (len < 2) {
    return hostname;
  }

  const first = split[len - 1];
  const second = split[len - 2];
  return `.${second}.${first}`;
}

const defaultOptions: CookieOptions = {
  path: "/",
  domain: getCookieDomain(),
  "max-age": 30 * SECONDS_IN_DAY,
};

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {}
): void {
  const allOptions: CookieOptions = {
    ...defaultOptions,
    ...options,
  };

  if (allOptions.expires instanceof Date) {
    allOptions.expires = allOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  const optionsKeys = Object.keys(allOptions) as CookieOptionsKey[];
  optionsKeys.forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`;
    const optionValue = allOptions[optionKey];

    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  console.log("updatedCookie", updatedCookie);

  document.cookie = updatedCookie;
}

export function deleteCookie(name: string, options?: CookieOptions): void {
  setCookie(name, "", {
    ...defaultOptions,
    ...options,
    "max-age": -1,
  });
}

export function getCookie(name: string): string | undefined {
  if (typeof window === "undefined") return undefined;

  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    )
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}
