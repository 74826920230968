/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button/Button";
import { Input } from "@/components/Input/Input";
import { useToast } from "@/hooks/useToast";

import { updateCurrency } from "./api";
import cls from "./Currencies.module.scss";
import { CurrencyType } from "./types";

import { useDependenciesStore } from "../store";

type Props = {
  closeModal?: () => void;
  currency: CurrencyType;
};

export const UpdateCurrency: React.FC<Props> = ({ closeModal, currency }) => {
  const { t } = useTranslation();
  const { showError } = useToast();

  const [code, setCode] = useState(currency.code);
  const [name, setName] = useState(currency.name);
  const [symbol, setSymbol] = useState(currency.symbol);

  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchCurrencies = useDependenciesStore((s) => s.fetchCurrencies);

  const isValid = !!code && !!name && !!symbol;

  const onCurrencyUpdate = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      const req = {
        name,
        code,
        symbol,
        id: currency._id,
      };
      await updateCurrency(req);
      fetchCurrencies();
      closeModal && closeModal();
    } catch (err) {
      const error = err as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        showError(error);
      }
      setError(true);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isError && !errorMessage) return;

    setError(false);
    setErrorMessage("");
  }, [code, name, symbol]);

  return (
    <div className={cls.create_root}>
      {errorMessage && <p className={cls.error}>{errorMessage}</p>}
      <Input
        value={code}
        onChange={(e) => setCode(e.currentTarget.value)}
        placeholder={t("currency.code")}
        error={isError}
      />
      <Input
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        placeholder={t("currency.name")}
        error={isError}
      />
      <Input
        value={symbol}
        onChange={(e) => setSymbol(e.currentTarget.value)}
        placeholder={t("currency.symbol")}
        error={isError}
      />
      <Button onClick={onCurrencyUpdate} disabled={loading || !isValid}>
        {t("common.save")}
      </Button>
    </div>
  );
};
