import { AxiosResponse } from "axios";

import { axiosApi } from "@/api/api";
import { APIResponse } from "@/api/types";

import { GetAllGendersRes } from "./types";

export const getAllGenders = async (): Promise<
  AxiosResponse<APIResponse<GetAllGendersRes>>
> => {
  return await axiosApi.get("/genders/all");
};
