import { useNavigate } from "react-router-dom";

import { EmptyPhotoPlaceholder } from "@/components/EmptyPhotoPlaceholder/EmptyPhotoPlaceholder";
import { useAdvertName } from "@/hooks/useAdvertName";
import { HorseAdvert } from "@/modules/SellList/types";
import { STATUS_COLORS, getImageSrc } from "@/utils/consts";

import cls from "./MiniAdvert.module.scss";

type Props = {
  advert: HorseAdvert;
};

export const MiniAdvert: React.FC<Props> = ({ advert }) => {
  const imgSrc = advert.photos && advert.photos[0] ? advert.photos[0] : "";
  const advertTitle = useAdvertName(advert);
  const statusBackgroundColor = STATUS_COLORS[advert.status];
  const navigate = useNavigate();

  return (
    <button
      className={cls.advert}
      onClick={() => navigate(`/dashboard/edit-advert/${advert._id}`)}
    >
      <div className={cls.pic}>
        {imgSrc ? (
          <img src={getImageSrc(imgSrc)} />
        ) : (
          <EmptyPhotoPlaceholder compact />
        )}
        <div
          className={cls.status}
          style={{ backgroundColor: statusBackgroundColor.bg }}
        />
      </div>
      <div className={cls.info}>
        <h2>{advertTitle}</h2>
        <p>{advert.description}</p>
      </div>
    </button>
  );
};
