import { Outlet } from "react-router-dom";

import cls from "./DashboardLayout.module.scss";
import { Header } from "./Header/Header";
import { Nav } from "./Nav/Nav";

export const DashboardLayout: React.FC = () => {
  return (
    <div className={cls.root}>
      <header className={cls.header}>
        <Header />
      </header>
      <div className={cls.main}>
        <aside className={cls.nav}>
          <Nav />
        </aside>
        <div className={cls.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
