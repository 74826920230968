import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";

import { APIResponse } from "@/api/types";
import { Nullable } from "@/utils/types";

export function useFetch<T>(
  apiMethod: () => Promise<AxiosResponse<APIResponse<T>, unknown>>,
  refreshKey?: number
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<APIResponse<T>>({
    data: [],
  } as APIResponse<T>);

  const fetch = useCallback(async () => {
    setLoading(true);

    try {
      const r = await apiMethod();
      setData(r.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [apiMethod]);

  useEffect(() => {
    fetch();
  }, [fetch, refreshKey]);

  return { data: data.data, loading };
}

export function useFetchWithParams<T, K>(
  apiMethod: (params: K) => Promise<AxiosResponse<APIResponse<T>, unknown>>,
  params: Nullable<K> | K,
  resetData?: boolean
): { data: T; loading: boolean } {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<APIResponse<T>>({
    data: [],
  } as APIResponse<T>);

  const fetch = useCallback(async () => {
    if (params) {
      setLoading(true);

      try {
        const r = await apiMethod(params);
        setData(r.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setData((prev) =>
        resetData ? ({ ...prev, data: [] } as APIResponse<T>) : prev
      );
    }
  }, [apiMethod, params, resetData]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data: data.data, loading };
}
