import { format } from "date-fns/format";
import { TFunction } from "i18next";

export function formatDate(d: Date, pattern: string): string {
  return format(d, pattern);
}

export function getDateString(
  date: Date,
  lang: string,
  options?: Intl.DateTimeFormatOptions
): string {
  return date.toLocaleString(lang, {
    month: "long",
    day: "numeric",
    localeMatcher: "best fit",
    ...options,
  });
}

export function getDateTimeString(date: Date, lang: string, t: TFunction) {
  const dateString = () => {
    switch (true) {
      case isToday(date):
        return t("common.today");
      case isYesterday(date):
        return t("common.yesterday");
      default:
        return getDateString(date, lang);
    }
  };

  return `${dateString()}, ${dateToTime(date)}`;
}

export function getWeekName(date: Date, lang: string): string {
  return date.toLocaleDateString(lang, { weekday: "long" });
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return date.toLocaleDateString("ru") === today.toLocaleDateString("ru");
}

export function isYesterday(date: Date): boolean {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return date.toLocaleDateString("ru") === yesterday.toLocaleDateString("ru");
}

export function getDateAgo(daysAgo: number): Date {
  const d = new Date();
  const pastDate = d.getDate() - daysAgo;
  d.setDate(pastDate);
  return d;
}

export function secondsToHHMMSS(seconds: number): string {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function secondsToHHMM(seconds: number): string {
  return new Date(seconds * 1000).toISOString().substr(11, 5);
}

export function dateWithOffset(date: Date): Date {
  return new Date(
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  );
}

export function diffInHours(later: Date, earlier: Date) {
  let diff = (later.getTime() - earlier.getTime()) / 1000;
  diff /= 60 * 60;

  return Math.abs(Math.round(diff));
}

export function secondsToDate(dateInSeconds: string | number): Date {
  const validDate =
    typeof dateInSeconds === "string" ? Number(dateInSeconds) : dateInSeconds;
  return new Date(validDate * 1000);
}

export function dateToTime(date: Date): string {
  return date.toLocaleTimeString("ru", {
    hour: "numeric",
    minute: "numeric",
  });
}
