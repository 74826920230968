import { animated, useTransition } from "@react-spring/web";
import { useTranslation } from "react-i18next";

import { Spinner } from "@/components/Spinner/Spinner";
import { MiniAdvert } from "@/modules/Advert/MiniAdvert/MiniAdvert";
import { HorseAdvert } from "@/modules/SellList/types";

import cls from "./SearchDropdown.module.scss";

type Props = {
  data: HorseAdvert[];
  loading?: boolean;
  isOpen?: boolean;
};

export const SearchDropdown: React.FC<Props> = ({ data, loading, isOpen }) => {
  const { t } = useTranslation();

  const transition = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return transition(
    (style, item) =>
      item && (
        <animated.div className={cls.wrapper} style={style}>
          <div className={cls.root}>
            <ul className={cls.list}>
              {!loading && (
                <>
                  {data.length !== 0 &&
                    data.map((advert) => {
                      return (
                        <li key={advert._id}>
                          <MiniAdvert advert={advert} />
                        </li>
                      );
                    })}

                  {data.length === 0 && (
                    <p className={cls.empty}>{t("search.advertsNotFound")}</p>
                  )}
                </>
              )}

              {loading && (
                <li className={cls.spinner}>
                  <Spinner />
                </li>
              )}
            </ul>
          </div>

          <div className={cls.backdrop} />
        </animated.div>
      )
  );
};
