import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@/components/Grid/Grid";
import { TableData, useTableData } from "@/hooks/useTableData";

import { CurrencyActions } from "./CurrencyActions";
import { CurrencyType } from "./types";

type Props = {
  currencies: CurrencyType[];
};

type MappedData = Omit<CurrencyType, "_id"> & { actions: JSX.Element };

export const CurrenciesTable: React.FC<Props> = ({ currencies }) => {
  const { t } = useTranslation();

  const mappedData = useMemo<MappedData[]>(
    () =>
      currencies.map((currency) => {
        return {
          ...currency,
          actions: <CurrencyActions currency={currency} />,
        };
      }),
    [currencies]
  );

  const tableData: TableData<MappedData> = {
    data: mappedData,
    columns: [
      {
        title: t("currency.name"),
        accessor: "name",
      },
      {
        title: t("currency.code"),
        accessor: "code",
      },
      {
        title: t("currency.symbol"),
        accessor: "symbol",
      },
      {
        title: t("currency.actions"),
        accessor: "actions",
      },
    ],
  };

  const { data, columns } = useTableData(tableData);

  return (
    <>
      <Grid data={data} columns={columns} />
    </>
  );
};
