import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { ExternalToast, toast } from "sonner";

const defaultToastOptions: ExternalToast = {
  duration: 3000,
  position: "top-center",
};

const successToastOptions = {
  ...defaultToastOptions,
  style: { backgroundColor: "var(--clr-green)", color: "var(--clr-white)" },
};

const errorToastOptions = {
  ...defaultToastOptions,
  style: { backgroundColor: "var(--clr-red)", color: "var(--clr-white)" },
};

export const useToast = () => {
  const { t } = useTranslation();

  const showSuccess = (message: string) => {
    toast.success(message, successToastOptions);
  };

  const showError = (error: unknown) => {
    if (error instanceof AxiosError) {
      toast.error(error.message, errorToastOptions);
      return;
    }

    if (error && typeof error === "object" && "message" in error) {
      toast.error(error.message as string, errorToastOptions);
      return;
    }

    if (error && typeof error === "string") {
      toast.error(error as string, errorToastOptions);
      return;
    }

    toast.error(t("common.error"), errorToastOptions);
    console.error(t("common.error"), error);
  };

  return { showSuccess, showError };
};
