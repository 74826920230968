import cn from "classnames";
import { useTranslation } from "react-i18next";

import cls from "./EmptyPhotoPlaceholder.module.scss";
import { CameraIcon } from "./icons";

type Props = {
  compact?: boolean;
};

export const EmptyPhotoPlaceholder: React.FC<Props> = ({ compact }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(cls.root, { [cls.compact]: compact })}>
      <CameraIcon />
      <h3>{t("common.noPhoto")}</h3>
    </div>
  );
};
